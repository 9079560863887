import axios from 'axios';
import React, { useState } from 'react'
import {toast} from "react-toastify"

function ContactForm() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        axios.post("https://glittery-cajeta-6bbaa7.netlify.app/.netlify/functions/api/contact",{
          name:formData.name, 
          email:formData.email,
          query:formData.message,
         
        }).then(res => {
          console.log(res,"res");
         toast.success("We will get back to you soon !!")
         setFormData({name:"",email:"",message:""})
        }).catch(err => console.log(err,"quote err") )
    
       
      };
    

  return (
    <div className="form-container">
            <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Company Name:</label>
          <input
            type="text"
            id="name"
            placeholder='Your Company Name'
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder='Your Email'

            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Query:</label>
          <textarea
            id="message"
            placeholder='Your Query'
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
  )
}

export default ContactForm