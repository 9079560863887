import { Routes, Route } from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import FAQ from "./pages/FAQ";
import Home from "./pages/Home";
import PartnerPage from "./pages/PartnerPage";
import AirportPage from "./pages/AirportPage";
import SingleTransport from "./pages/SingleTransport";
import EuropeTour from "./component/EuropeTour";
import PaymentGateway from "./component/PaymentGateway";

import Destinations from "./component/Destinations";
import HomeDestinations from "./component/HomeDestinations";
import SingleDestination from "./component/SingleDestination";
import PaymentResult from "./component/PaymentResult";
import Basket from "./component/Basket";
import ContactUs from "./pages/Contact";
import Terms from "./pages/Terms";
import LegaLNotice from "./pages/LegalNotice";
import Accessibility from "./pages/Accessibility";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import GreenInitiative from "./pages/GreenInitiative";
import Career from "./pages/Career";

function App() {
  return (
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='europe-tour' element={<EuropeTour main={true} />}></Route>
        <Route path='contact-us' element={<ContactUs />}></Route>
        <Route path='faq' element={<FAQ />}></Route>
        <Route path='partners' element={<PartnerPage />}></Route>
        <Route path='airport' element={<AirportPage />}></Route>
        <Route path='transport/:route' element={<SingleTransport />}></Route>
        <Route path='transport' element={<SingleTransport />}></Route>
        <Route path='payment' element={<PaymentGateway/>}></Route>
        <Route path='basket' element={<Basket />}></Route>
        <Route path='privacy-policy' element={<PrivacyPolicy />}></Route>
        <Route path='accessibility' element={<Accessibility />}></Route>
        <Route path='legal-notice' element={<LegaLNotice />}></Route>
        <Route path='about' element={<AboutPage />}></Route>
        <Route path='career' element={<Career />}></Route>
        <Route path='green-initiative' element={<GreenInitiative />}></Route>
        <Route path='terms' element={<Terms />}></Route>
        <Route path='success' element={<PaymentResult result="success" />}></Route>
      </Routes>
  );
}
export default App;
