import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import Navbar from './Navbar';

 // Replace with your actual Stripe publishable key

const PaymentForm = () => {
  const [stripe, setStripe] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');

  // useEffect(() => {
  //   // Fetch client secret from your server
  //   // Note: You need to implement your server logic to create a PaymentIntent
  //   // The client secret is returned from the server to confirm the payment on the client side

  //   // For testing purposes, you can manually create a PaymentIntent on the server and get the client secret
  //   // Example server endpoint: '/create-payment-intent'
  //   fetch('/create-payment-intent', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       items: [{ id: 'item-id' }],
  //       name,
  //       surname,
  //       email,
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data,"details");
  //       setClientSecret(data.clientSecret);
  //     });

  //   // Initialize Stripe.js
  //   const initializeStripe = async () => {
  //     const stripeInstance = await stripePromise;
  //     setStripe(stripeInstance);
  //   };

  //   initializeStripe();
  // }, [name, surname, email]);

  const handlePayment = async () => {
    const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_KEY);


    const response =  await fetch('https://glittery-cajeta-6bbaa7.netlify.app/.netlify/functions/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: [{ id: 'item-id' }],
        name,
        surname,
        email,
      }),
    })

const session = await response.json()    
console.log(session,"session");
const result = stripePromise.redirectToCheckout({sessionId:session.id})
console.log(result,"result");
    // .then((res) => {
    //   console.log(res.json(),"res");    
    //   res.json()})
    // .then((data) => {
    //   console.log(data,"details");
    //   stripePromise.redirectToCheckout({sessionId:data?.id})
    //   setClientSecret(data.clientSecret);
    // }).catch(err => console.log(err,"stripe_error"))

    // // Confirm the card payment with Stripe.js
    // const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card: stripe.elements.getElement('card'), // Assumes you have Stripe Elements integrated for card input
    //     billing_details: {
    //       name: `${name} ${surname}`,
    //       email,
    //     },
    //   },
    // });

    // if (error) {
    //   console.error(error.message);
    // } else if (paymentIntent.status === 'succeeded') {
    //   console.log('Payment succeeded:', paymentIntent);
    //   // Handle success, e.g., show a success message to the user
    // }
  };

  const handleExpiryChange = (e) => {
    const value = e.target.value;
    // Format expiry as MM/YY (assuming a two-digit year)
    if (/^\d{0,2}\/?\d{0,2}$/.test(value)) {
      setExpiry(value);
    }
  };

  const handleCvcChange = (e) => {
    const value = e.target.value;
    // Allow only numeric input and limit to 3 characters
    if (/^\d{0,3}$/.test(value)) {
      setCvc(value);
    }
  };

  const handleCardNumberChange = (e) => {
    // Allow only numeric input for card number
    const value = e.target.value.replace(/\D/g, '');
    setCardNumber(value);
  };

  const handleNameChange = (e) => {
    // Allow only alphabetic characters for name
    const value = e.target.value.replace(/[^A-Za-z]/g, '');
    setName(value);
  };

  const handleSurnameChange = (e) => {
    // Allow only alphabetic characters for surname
    const value = e.target.value.replace(/[^A-Za-z]/g, '');
    setSurname(value);
  };

  return (
    <StyledPaymentForm>
      <h2>Payment Details</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <label>
          Name:
          <input type="text" value={name} onChange={handleNameChange} />
        </label>
        <label>
          Surname:
          <input type="text" value={surname} onChange={handleSurnameChange} />
        </label>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <label className="card-label">
          Card Number:
          <input type="text" value={cardNumber} onChange={handleCardNumberChange} />
        </label>
        <div className="card-details">
          <label>
            Expiry Date:
            <input type="text" value={expiry} onChange={handleExpiryChange} placeholder="MM/YY" />
          </label>
          <label>
            CVC:
            <input type="text" value={cvc} onChange={handleCvcChange} maxLength="3" />
          </label>
        </div>
        <button className="pay-button" onClick={handlePayment}>
          Pay Now
        </button>
      </form>
    </StyledPaymentForm>
  );
};

const StyledPaymentForm = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .card-label {
    position: relative;
  }

  .card-label::after {
    content: 'Card Number';
    position: absolute;
    top: 10px;
    left: 10px;
    color: #888;
    pointer-events: none;
    z-index: 1;
  }

  .card-details {
    display: flex;
    gap: 10px;
  }

  .card-details label {
    flex: 1;
  }

  .pay-button {
    background-color: #175af5;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .pay-button:hover {
    background-color: #0f4db2;
  }
`;

export default PaymentForm;