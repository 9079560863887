import React, { useState } from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import styled from 'styled-components'
import image from "../images/about.jpg"
import image2 from "../images/mission.webp"

function AboutPage() {

  return  <Wrapper>
  <Navbar />
    <section className='hero-section'>
  <div className='img-container'>
    <img src={image} alt='' />
    <div className='section-title'>WHO WE ARE </div>
  </div>


</section>

  <div className="about-container" >
    <div className="content" >
      <h2>Our Mission</h2>
      <span className="line" />
      <p>Welcome to Luxury Chauffeur Service Ltd, your premier destination for luxurious transportation experiences. With a dedication to excellence and a commitment to exceeding expectations, we take pride in offering impeccable service tailored to your unique needs.</p>
      <p>At Luxury Chauffeur Service Ltd, we understand that every journey is an opportunity to create lasting impressions. Whether you're traveling for business or leisure, our professional chauffeurs are dedicated to providing a seamless and luxurious experience from start to finish.</p>
      <p>With a diverse fleet of meticulously maintained vehicles, we offer a range of options to suit your preferences and requirements. From elegant sedans to spacious SUVs, each vehicle in our collection is equipped to provide comfort, style, and sophistication.</p>
      <p>In addition to our premium transportation services, we also offer bespoke Europe Tours designed to showcase the beauty and charm of the continent. Whether you're exploring iconic landmarks, picturesque countryside, or hidden gems off the beaten path, our experienced team will ensure you experience Europe in unparalleled comfort and style.</p>
      <p>Whether you're in need of airport transfers, corporate travel, special event transportation, or a memorable Europe Tour, you can trust Luxury Chauffeur Service Ltd to deliver an experience that exceeds your expectations.</p>
      <p>Experience the epitome of luxury transportation with Luxury Chauffeur Service Ltd. Sit back, relax, and let us take you where you need to go in style.</p>

     <div className="values" >
  <div className="choose-content" >
      <h2>Why Choose US?</h2>
      <span className="line" />
      <p>At Bespoke London Chauffeurs, we take pride in offering bespoke services, tailoring every aspect of your experience to match your unique preferences. Our team of professional and experienced chauffeurs, ensures your journey is not only safe but also exceptionally smooth. Travel in style with our luxury fleet, promising a comfortable and stylish ride, while our unwavering reliability ensures a service you can trust.</p>
      <p>Your comfort and satisfaction are our highest priorities. Our commitment to privacy and comfort ensures that you can relax and enjoy your journey in a setting that suits your preferences. At Bespoke London Chauffeurs, we make sure every aspect of your ride embodies the luxury and quality that you deserve.</p>
      <div className="core-content" >
      <h2>Our Core Values</h2>
      <span className="line" />
      <p>Our core values encompass integrity, a focus on customer needs, safety, adaptability, and value. These principles guide us to prioritize excellence and client satisfaction in everything we do.</p>
  <ul>
    <li>Conducting ourselves with the utmost integrity, courtesy, and reliability</li>
    <li>Placing our clients' needs and preferences at the forefront of our operations</li>
    <li>Prioritizing the well-being and security of our clients and employees</li>
    <li>Adapting to changing client needs and market dynamics</li>
    <li>Delivering value for our clients without compromising quality</li>
  </ul>
    </div>
    </div>
    

  </div>
    </div>
    <div className="img" >
      <img src={image2} />
    </div>
  </div>

  {/* <div className="values" >
  <div className="choose-content" >
      <h2>Why Choose US?</h2>
      <span className="line" />
      <p>At Bespoke London Chauffeurs, we take pride in offering bespoke services, tailoring every aspect of your experience to match your unique preferences. Our team of professional and experienced chauffeurs, ensures your journey is not only safe but also exceptionally smooth. Travel in style with our luxury fleet, promising a comfortable and stylish ride, while our unwavering reliability ensures a service you can trust.</p>
      <p>Your comfort and satisfaction are our highest priorities. Our commitment to privacy and comfort ensures that you can relax and enjoy your journey in a setting that suits your preferences. At Bespoke London Chauffeurs, we make sure every aspect of your ride embodies the luxury and quality that you deserve.</p>
      <div className="core-content" >
      <h2>Our Core Values</h2>
      <span className="line" />
      <p>Our core values encompass integrity, a focus on customer needs, safety, adaptability, and value. These principles guide us to prioritize excellence and client satisfaction in everything we do.</p>
  <ul>
    <li>Conducting ourselves with the utmost integrity, courtesy, and reliability</li>
    <li>Placing our clients' needs and preferences at the forefront of our operations</li>
    <li>Prioritizing the well-being and security of our clients and employees</li>
    <li>Adapting to changing client needs and market dynamics</li>
    <li>Delivering value for our clients without compromising quality</li>
  </ul>
    </div>
    </div>
    

  </div> */}
  <Footer />
  </Wrapper>

}

const Wrapper = styled.section`
.hero-contents h1{
  text-align:center;
  max-width:inherit
}

.hero-section{
  margin-bottom:0
}


.img-container {
  position: relative;
  text-align: center;
  height:auto
}

.section-title {
  font-size: clamp(1.5625rem, 0.1875rem + 5.5vw, 5rem);
  position: absolute;
  color: black;
font-weight:bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-container{
  display:flex;
  justify-content:space-between;
 width:90%;
 ${'' /* align-items:center; */}
 gap:80px;
  margin:40px auto

}

.values{
  display:flex;
  justify-content:space-between;
  max-width: 1140px;
  margin-top:30px
 ${'' /* gap:80px; */}
  ${'' /* margin:60px auto */}

}

.content {
  ${'' /* width:100%; */}

  span.line{
    border-bottom: 4px solid goldenrod;
    width: 10%;
    display: block;
    margin: 15px 0 30px;
  
}
h2{
  font-size: 2rem;
    font-weight: bold;
}
p {
  color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 600;
}
} 

.choose-content {
  ${'' /* padding: 0px 100px 0px 0px;
  width:50%; */}

  span.line{
    border-bottom: 4px solid goldenrod;
    width: 10%;
    display: block;
    margin: 15px 0 30px;
  
}
h2{
  font-size: 2rem;
    font-weight: bold;
}
p {
  color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 1rem;
    font-weight: 600;
}
} 
.core-content {
  ${'' /* width:50%;

  padding: 0px 100px 0px 0px; */}

  span.line{
    border-bottom: 4px solid goldenrod;
    width: 10%;
    display: block;
    margin: 15px 0 30px;
  
}
h2{
  font-size: 2rem;
    font-weight: bold;
}
p {
  color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 1rem;
    font-weight: 600;
}

li{
  color: #000000;
  list-style:disc;
    font-family: "Poppins", Sans-serif;
    font-size: 1rem;
    font-weight: 800;
    margin-bottom:10px
}
} 

.img img{
    width: 100%;
    height: auto;
    max-width: 100%;
    border: none;
    filter: brightness(105%) contrast(96%) saturate(74%) blur(0px) hue-rotate(347deg);
    border-style: solid;
    border-width: 8px 8px 8px 8px;
    border-color: #000000;
    border-radius: 0px 0px 0px 0px;
}

@media (max-width: 1000px) {
  .about-container{
  flex-direction:column-reverse
}

.img img{
   
    aspect-ratio: 1 / 1.5;
}

.values{
  display:flex;
  justify-content:space-between;
  flex-direction:column;
 width:80%;
 align-items:center;
 gap:80px;
  margin:40px auto
}

.core-content,.choose-content{
  padding:0;
  width:100%
}

.content{
  width:100%
}
  }
`;

export default AboutPage;
