import React from "react";
import AirportInfo from "../component/AirportInfo";
import Footer from "../component/Footer";
import Hero from "../component/Hero";
import HomeDestinations from "../component/HomeDestinations";
import Tripad from "../component/Tripad";
import Destinations from "../component/Destinations";
import Booking from "../component/Booking";
import styled from "styled-components";
// import Tripad from "../component/Tripad";

function Home() {
  return (
    <>
      <Hero form />
      {/* <Tripad /> */}
      <HomeDestinations main />
      <AirportInfo />
      <Wrapper>
        <div className="core-content">
          <h2>Why Choose Us</h2>
          <span className="line" />
          <p>
            Our core values encompass integrity, a focus on customer needs,
            safety, adaptability, and value. These principles guide us to
            prioritize excellence and client satisfaction in everything we do.
          </p>
          <ul>
            <li>
              Conducting ourselves with the utmost integrity, courtesy, and
              reliability
            </li>
            <li>
              Placing our clients' needs and preferences at the forefront of our
              operations
            </li>
            <li>
              Prioritizing the well-being and security of our clients and
              employees
            </li>
            <li>Adapting to changing client needs and market dynamics</li>
            <li>
              Delivering value for our clients without compromising quality
            </li>
          </ul>
        </div>

        <div className="end" style={{ marginTop: "3rem" }}>
          <span />
          <p>Luxury Chauffeur Service Ltd</p>
          <span />
        </div>
      </Wrapper>

      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 767px) {
    padding: 0 1rem;
  }

  .core-content {
    span.line {
      border-bottom: 4px solid goldenrod;
      width: 10%;
      display: block;
      margin: 15px 0 30px;
    }
    h2 {
      font-size: 2rem;
      font-weight: 500;
    }
    p {
      color: #000000;
      font-family: "Poppins", Sans-serif;
      font-size: 1rem;
      font-weight: 500;
    }

    li {
      color: #000000;
      list-style: disc;
      font-family: "Poppins", Sans-serif;
      font-size: 1rem;
      margin-bottom: 10px;
    }
  }

  .end {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    span {
      border-bottom: 2px solid;
      width: 10%;
      display: block;
      border-bottom: 2px solid;
      width: 10%;
      display: block;
    }

    p {
      margin: 0 10px;
      font-size: 20px;
      font-weight: 600;

      @media (max-width: 576px) {
        font-size: 16px;
        text-align: center;
      }
    }
  }
`;

export default Home;
