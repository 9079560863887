import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { MdLocationPin } from "react-icons/md";
import { VscCalendar } from "react-icons/vsc";
import { TbArrowsRightLeft } from "react-icons/tb";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { FaRegUser, FaRegHourglass } from "react-icons/fa";
import {
  Autocomplete,
  LoadScript,
  useJsApiLoader,
} from "@react-google-maps/api";
import { RxCross2 } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LocationSearchInput from "./searchLocation";

const Book = ({
  from_place = "",
  to_place = "",
  d_date,
  r_date,
  trip_type,
  num_people,
  num_hrs,
}) => {
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: "AIzaSyBR-sG3ZqtzdXS0HvgGKu4qwHAEQcy1jO4",
  //   libraries: ["places"],
  // });
  // const [typingFrom, setTypingFrom] = useState("");
  // const [typingTo, setTypingTo] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [departureDate, setDepartureDate] = useState(d_date ? d_date : "");
  const [returnDate, setReturnDate] = useState(r_date ? r_date : "");
  const [tripType, setTripType] = useState(trip_type ? trip_type : "one-way");
  const [numPeople, setNumPeople] = useState(num_people ? num_people : 1);
  const [numHours, setNumHours] = useState(num_hrs ? num_hrs : 0);
  const [fromAutocomplete, setFromAutocomplete] = useState(null);
  const [toAutocomplete, setToAutocomplete] = useState(null);
  const [origin, setOrigin] = useState(from_place ? from_place : null);
  const [destination, setDestination] = useState(to_place ? to_place : null);
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(originRef,"originRef");
  console.log(location, "location");

  console.log(origin, "origin");
  console.log(searchResult, "searchResult");
  console.log(departureDate, "departureDate");

  // useEffect(() => {
  //   if (typingFrom) {
  //     if(from_place){
  //       setTypingFrom(from_place);
  //     }else{

  //       typingFrom?.addListener("place_changed", () => {
  //         setTypingFrom(typingFrom);
  //       });
  //     }
  //   }

  //   if (typingTo) {
  //     typingTo.addListener("place_changed", () => {
  //       setTypingTo(typingTo);
  //     });
  //   }
  // }, [typingFrom, typingTo]);

  const libraries = ["places"]

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBR-sG3ZqtzdXS0HvgGKu4qwHAEQcy1jO4",
    libraries: libraries,
  });
  if (!isLoaded) {
    return <p>Loading...</p>;
  }
  const incrementPeople = () => {
    if (numPeople < 6) {
      setNumPeople((prev) => prev + 1);
    }
  };

  const decrementPeople = () => {
    if (numPeople > 0) {
      setNumPeople((prev) => prev - 1);
    }
  };

  const incrementHours = () => {
    setNumHours((prev) => prev + 1);
  };

  const decrementHours = () => {
    if (numHours > 0) {
      setNumHours((prev) => prev - 1);
    }
  };

  const handleTripTypeClick = (type) => setTripType(type);

  const handleSeePricesClick = async (e) => {
    e.preventDefault();

    let miles;
    let origin_point = "";
    let destination_point = "";

    if (tripType != "hourly") {
      if (!origin || !destination) {
        toast.error("Places required");
        return;
      }
    } else {
      if (!numHours) {
        toast.error("Hours required");
        return;
      }
    }

    origin_point = origin;
    destination_point = destination;

    if (destination_point) {
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: origin_point,
        destination: destination_point,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        // eslint-disable-next-line no-undef
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      });

      miles = Number(results.routes[0].legs[0].distance.text.split(" ")[0]);
    }

    const dataToSave = {
      from: origin_point,
      to: destination_point,
      miles_covered: miles,
      departureDate,
      tripType,
      returnDate: tripType === "return" ? returnDate : undefined,
      numHours: tripType === "hourly" ? numHours : "",
      numPeople,
      predefined: false,
    };

    // Save data in local storage
    localStorage.setItem("bookingData", JSON.stringify(dataToSave));

    if (location.pathname == "/transport") {
      window.location.reload();
    } else {
      navigate("/transport");
    }
    // TODO: Send the data to your backend Node API here
  };

  return (
    // <LoadScript
    //   googleMapsApiKey="AIzaSyBR-sG3ZqtzdXS0HvgGKu4qwHAEQcy1jO4"
    //   libraries={['places']}
    // >
    <Wrapper>
      <section className="book-section">
        <div className="book-container">
          <div className="buttons">
            <button
              className={tripType === "return" ? "btn-active" : ""}
              onClick={() => handleTripTypeClick("return")}
            >
              with return
            </button>
            <button
              className={tripType === "one-way" ? "btn-active" : ""}
              onClick={() => handleTripTypeClick("one-way")}
            >
              one way
            </button>
            <button
              className={tripType === "hourly" ? "btn-active" : ""}
              onClick={() => handleTripTypeClick("hourly")}
            >
              Hourly
            </button>
          </div>

          <form className="book-form">
            {/* <LocationInput label="from" typing={typingFrom} value={typingFrom} setTyping={setTypingFrom}  /> */}

            <LocationSearchInput
              label="from (enter a postcode)"
              setOrigin={setOrigin}
              origin={origin}
            />

            {tripType !== "hourly" && <TbArrowsRightLeft />}
            {tripType !== "hourly" && (
              <LocationSearchInput
                label="to (enter a postcode)"
                setOrigin={setDestination}
                origin={destination}
              />
            )}
            {/* <LocationInput label="to" typing={typingTo} value={to_place ? to_place :  typingTo ? typingTo?.getPlace()?.formatted_address : ""}  setTyping={setTypingTo} /> */}

            <div className="departure-cont">
              <DateInput
                label="departure"
                setDate={setDepartureDate}
                value={departureDate}
              />
              {tripType === "return" && (
                <DateInput
                  label="return"
                  departureDate={departureDate}
                  setDate={setReturnDate}
                  value={returnDate}
                />
              )}
            </div>

            {tripType === "hourly" && (
              <HourlyInput
                num={numHours}
                increment={incrementHours}
                decrement={decrementHours}
              />
            )}
            <PeopleInput
              num={numPeople}
              increment={incrementPeople}
              decrement={decrementPeople}
            />

            <button
              className="submit-button"
              type="button"
              onClick={handleSeePricesClick}
            >
              see prices
            </button>
          </form>
        </div>
      </section>
    </Wrapper>
    // </LoadScript>
  );
};

const LocationInput = ({
  label,
  typing,
  setTyping,
  country,
  refvalue,
  value,
}) => (
  <div className="from-cont">
    <MdLocationPin />
    <Autocomplete
      // onLoad={(autocomplete) => setSearchResult(autocomplete)}
      // onPlaceChanged={() => setTyping(false)}
      // types={['postal_code']}
      options={{ componentRestrictions: { country: country || "UK" } }}
    >
      <input type="text" className={label} placeholder={label} value={value} />
    </Autocomplete>
  </div>
);

export const DateInput = ({ label, setDate, value, min, departureDate }) => (
  <div className="from-cont">
    <VscCalendar />
    <input
      type="date"
      className={label}
      min={
        label == "return"
          ? departureDate
          : new Date().toISOString().split("T")[0]
      }
      onChange={(e) => setDate(e.target.value)}
      value={value}
      placeholder={label}
    />
  </div>
);

const HourlyInput = ({ num, increment, decrement }) => (
  <div className="hours-cont">
    <div className="from-cont">
      <AiOutlineMinus className="count" onClick={decrement} />
      <FaRegHourglass />
      <input type="text" readOnly className="hours" value={num} />
      <AiOutlinePlus className="count" onClick={increment} />
    </div>
  </div>
);

export const PeopleInput = ({ num, increment, decrement }) => (
  <div className="people-cont">
    <div className="from-cont">
      <AiOutlineMinus className="count" onClick={decrement} />
      <FaRegUser />
      <input type="text" readOnly className="people" value={num} />
      <AiOutlinePlus className="count" onClick={increment} />
    </div>
  </div>
);

const Wrapper = styled.section`
  .book-section {
    position: relative;
  }
  .book-container {
    position: relative;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  .book-container button {
    background-color: transparent;
    padding: 10px 15px;
    border: 0;
    color: #fff;
    border-radius: 10px 10px 0px 0;
    text-transform: capitalize;
  }
  .book-container .btn-active {
    background-color: #fff;
    color: #175af5;
  }
  .book-form > svg {
    color: #ccc;
    font-weight: 800;
    font-size: 1.6rem;
  }
  .book-form {
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: space-around;
    column-gap: 10px;
    padding: 20px 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .book-form .submit-button {
    background-color: blue;
    border-radius: 7px;
    color: #fff;
    width: 10%;
  }

  .book-form .submit-button:hover {
    background-color: black;
    transition: 0.5s ease-in-out;
  }

  .departure-cont {
    display: flex;
    width: 300px;
    /* background-color: red; */
    align-items: center;
    justify-content: center;
  }
  .departure-cont .from-cont {
    width: 50%;
    border-radius: 0;

    input {
      width: 100%;
      border: 0;
    }
  }
  .departure-cont .from-cont:first-child {
    border-radius: 5px 5px 5px 5px;
  }
  .departure-cont .from-cont:nth-child(2) {
    border-radius: 0px 5px 5px 0px;
  }
  .from-cont {
    border: 1px solid #ccc;
    padding: 0 5px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  .from-cont input {
    height: 100%;
    width: 100%;
    margin: 0;
    border: 0;
    background-color: transparent;
    text-transform: capitalize;
    outline: none;
  }
  .from-cont svg {
    font-size: 1.5rem;
    color: #ccc;
    margin-right: 5px;
  }
  .from-cont .count {
    color: #000;
    font-size: 0.8rem;
    margin: 0 5px;
  }
  .people-cont > button {
    background-color: #175af5;
    border-radius: 7px;
    color: #fff;
    width: 100%;
    &:hover {
      background-color: #4e7ff1;
      transition: 0.5s ease-in-out;
    }
  }
  .from-cont .people {
    width: 30px;
    font-size: 1.2rem;
    color: #555;
    margin: 0 5px;
  }
  .people-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }

  .hours-cont > button {
    background-color: #175af5;
    border-radius: 7px;
    color: #fff;
    width: 100%;
    &:hover {
      background-color: #4e7ff1;
      transition: 0.5s ease-in-out;
    }
  }
  .from-cont .hours {
    width: 30px;
    font-size: 1.2rem;
    color: #555;
    margin: 0 5px;
  }
  .hours-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }

  .options {
    position: absolute;
    /* bottom: 0; */
    top: 100px;
    left: 15px;
    width: 50%;

    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    display: none;
  }
  .option {
    padding: 10px 15px;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  .optionTo {
    left: unset;
  }
  .option:hover {
    background-color: #175af5;
    transition: 0.1s ease-in;
  }
  .show-option {
    display: block;
  }
  @media (max-width: 937px) {
    .departure-cont {
      width: 100%;
      margin-top: 20px;
    }

    .from-cont {
      width: 100%;
      margin-top: 20px;
    }

    .people-cont {
      width: 100%;
      column-gap: 10px;
      ${"" /* margin-top: 20px; */}
    }
    .book-container {
      max-width: 600px;
    }
    .submit-button {
      width: 100% !important;
      margin-top: 20px;
    }
  }
`;

export default Book;
