import React from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";

function Accessibility() {
  const containerStyle = {
    maxWidth: "800px",
    margin: "auto",
    padding: "20px",
    fontFamily: "AvenirNextDemiBold, Helvetica, Arial, Open Sans, sans-serif",
    lineHeight: "1.6",
  };

  const headingStyle = {
    color: "#333",
    fontSize: "32px",
    marginBottom: "10px",
    fontWeight: "600",
  };

  const sectionHeadingStyle = {
    color: "#666",
    fontSize: "20px",
    marginTop: "20px",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    marginBottom: "15px",
    fontSize: "16px",
    color: "rgba(0,0,0,0.6)",
    fontWeight: 500,
  };

  return (
    <div
      className="d-flex flex-column justify-content-between "
      style={{ minHeight: "100vh" }}
    >
      <div style={{ flex: 1 }}>
        <Navbar />
        <div style={containerStyle}>
          <h2 style={headingStyle}>Accessibility Statement</h2>

          <p style={paragraphStyle}>
            We’re committed to making our services accessible for everyone, and
            are constantly working to improve the experience of our users with
            any levels of impairment and ability. <br /> <br />
            We strive to meet all the Web Content Accessibility Guidelines 2.0.
            Our online booking process is optimized for those navigating without
            the use of a mouse, all main imagery has descriptive alt text in all
            the languages we offer service in, and there are more improvements
            to come. <br />
            <br />
            Have any feedback or suggestions related to accessibility? We are
            always looking to improve the most relevant touchpoints for our
            users, so please email us at{" "}
            <span
              style={{
                fontWeight: 700,
                textDecoration: "underline",
                textUnderlineOffset: "4px",
                cursor: "pointer",
              }}
            >
              {" "}
              luxurychauffeurserviceltd@outlook.com.
            </span>{" "}
            <br />
            <br />
            <span
              style={{
                fontWeight: 700,
                textDecoration: "underline",
                textUnderlineOffset: "4px",
                cursor: "pointer",
              }}
            >
              Visit our FAQs
            </span>
            {"   "}
            for our policy on accommodating travelers’ special needs.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Accessibility;
