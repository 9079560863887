import React from "react";
import SingleDestination from "./SingleDestination";
import BXL from "../images/BXL.jpg";
import Paris from "../images/Paris.jpg";
import Rotterdam from "../images/rotterdam.jpg";
import Amsterdam from "../images/Amsterdam.png";
import Milan from "../images/Milan.png";
import Geneva from "../images/geneva.jpeg";

function EuropeDestinations() {
  return (
    <div className='home-destinations'>
      <SingleDestination text="London to Belgium" image={BXL} price={790} />
      <SingleDestination text="London to Paris" image={Paris} price={"790"} />
      <SingleDestination text="London to Rotterdam" image={Rotterdam} price={"790"} />
      <SingleDestination text="London to Central Amsterdam" image={Amsterdam} price={"990"} />
      <SingleDestination text="London to Central Milan" image={Milan} price={"1,400"} />
      <SingleDestination text="London to Central Geneva" image={Geneva} price={"1,200"} />
    </div>
  );
}

export default EuropeDestinations;
