import React from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import styled from "styled-components";
import image from "../images/career.jpg";
import image2 from "../images/image 2.png";
import ContactForm from "../component/ContactForm";
import { IoFootsteps } from "react-icons/io5";
import { LuLeafyGreen } from "react-icons/lu";
import { FaHandHoldingHeart } from "react-icons/fa";
import { GiProfit } from "react-icons/gi";

function Career() {
  let careerArr = [
    {
      Icon: LuLeafyGreen,
      title: "Commitment to Sustainability",
      description:
        "We integrate green initiatives into our operations, offering rides in electric vehicles and promoting eco-conscious practices throughout the organization.",
    },
    {
      Icon: IoFootsteps,
      title: "Professional Development",
      description:
        "We value continuous learning and provide opportunities for growth and advancement within the company.",
    },
    {
      Icon: FaHandHoldingHeart,
      title: "Safety First",
      description:
        "Travel with confidence, knowing your safety is our top priority. Our best-in-class service includes rigorous health and cleaning standards.",
    },
    {
      Icon: GiProfit,
      title: "Competitive Benefits",
      description:
        "Enjoy competitive compensation packages, including health benefits and other perks.",
    },
  ];

  function CareerCard({ Icon, title, description }) {
    return (
      <div className="career_card">
        <Icon />
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    );
  }

  return (
    <Wrapper>
      <Navbar />

      <section className="hero-section">
        <div className="img-container">
          <div className="gradient"></div>
          <img src={image} alt="" />
          <div className="section-title">
            <h1>Grow Your Career With: </h1>
            <h1>Luxury Chauffeur Service Ltd</h1>
          </div>
        </div>
      </section>

      {/* Why Choose a Career with Luxury Chauffeur Service Ltd?

Commitment to Sustainability: We integrate green initiatives into our operations, offering rides in electric vehicles and promoting eco-conscious practices throughout the organization.

Professional Development: We value continuous learning and provide opportunities for growth and advancement within the company.

Exceptional Work Environment: Join a team of dedicated professionals who prioritize excellence in service and customer satisfaction.

Competitive Benefits: Enjoy competitive compensation packages, including health benefits and other perks. */}

      <div className="about-container">
        <div className="content">
          <p>
            <span>At Luxury Chauffeur Service Ltd</span>, we believe in
            fostering a culture of excellence, dedication, and sustainability.
            As a premier luxury transportation company committed to reducing our
            carbon footprint and preserving the environment, we are looking for
            passionate individuals who share our values and are ready to make a
            difference in the industry.
          </p>

          <h3>Why Choose a Career with Luxury Chauffeur Service Ltd?</h3>

          <div className="career_cards">
            {careerArr.map((k) => (
              <CareerCard {...k} />
            ))}
          </div>

          <ContactForm />
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  .hero-contents h1 {
    text-align: center;
    max-width: inherit;
  }

  .hero-section {
    margin-bottom: 0;
  }

  .career_cards {
    display: flex;
    /* justify-content: space-between; */
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
  }

  .career_card {
    width: 400px;
    overflow-wrap: break-word;
    text-align: center;
    ${"" /* background: mediumpurple; */}
    padding: 20px;

    svg {
      font-size: 50px;
      color: darkgoldenrod;
    }

    h2 {
      font-size: 24px;
      margin-top: 14px;
    }

    p {
      margin-top: 0 !important;
    }
  }

  .img-container {
    position: relative;
  }

  .form-container button {
    width: 100%;
  }

  .form-container {
    margin: 20px 0;
  }

  .about-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px;
  }

  .content {
    p {
      line-height: 26px;
      margin-top: 40px;
      font-size: 14px;
      font-weight: 300;
      font-family: sans-serif;
    }

    span {
      font-size: 25px;
    }

    h3 {
      margin-top: 4rem;
      font-size: 30px;
    }
  }

  .section-title {
    position: absolute;
    top: 10%;
    left: 40px;
    width: 800px;
  }

  .section-title h1 {
    font-size: 50px;
    color: white;
    margin-top: 20px;
  }

  .content {
    width: 60%;
    margin: auto;
  }

  @media (max-width: 1300px) {
    .content {
      width: 90%;
      margin: auto;
    }

    .career_cards {
      justify-content: center;
    }

    .connect {
      width: 90%;
      margin: 20px auto 0;
    }
  }
`;

export default Career;
