import React from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import styled from 'styled-components'
import image from "../images/green.png"
import green1 from "../images/green_1.jpg"
import green2 from "../images/green_2.jpg"
import green3 from "../images/green_3.jpg"
import image2 from "../images/image 2.png"

function GreenInitiative() {

  let greenArr = [
    {
      src:green1,
      title:"Electric Class",
      description:"Choose our new Electric Class to book chauffeured rides in top-tier electric vehicles like the Jaguar I-PACE, Tesla Model S, and Tesla Model X."
    },
    {
      src:green2,
      title:"The new normal",
      description:"Electric vehicles are also incorporated into our Business Class and First Class in many cities. Our current goal is to have 50% of our rides be electric by the end of 2025"
    },
    {
      src:green3,
      title:"100% carbon offset",
      description:"Whichever vehicle class you choose, we automatically offset the emissions with our carbon offset program."
    },
  ]

function CareerCard({src,title,description}) {
  return <div className="career_card" >
    <img src={src} />
<h2>{title}</h2>
<p>{description}</p>
  </div>
}

  return  <Wrapper>
    <Navbar />

  <div className='hero-section'>
 
    <img src={image} alt='' className="image" />
    {/* <button className='section-title'>Book a ride</button> */}

  
  <h2 className="heading" >Driving a greener future</h2>


</div>

  {/* <div className="about-container" >
    <div className="content" >
      <p><span>At Luxury Chauffeur Service Ltd</span>, we are proud to incorporate green initiatives into our luxury transportation offerings. As a premier chauffeur company, we understand the importance of reducing our carbon footprint and preserving the environment for future generations. That's why we are committed to integrating sustainable practices throughout our operations, including offering rides in electric vehicles as part of our diverse fleet.</p>
      <p>By choosing Luxury Chauffeur Service Ltd, you're not just selecting a mode of transportation; you're making a conscious decision to support environmentally friendly practices. While we offer a range of vehicles to suit your needs, including electric options, every ride with us contributes to reducing emissions and promoting cleaner air. Whether you're traveling for business or pleasure, you can rest assured that your journey with us aligns with your values of sustainability without compromising on luxury or comfort.</p>
      <p>Join us in our commitment to a greener future by experiencing the seamless blend of opulence and eco-consciousness with Luxury Chauffeur Service Ltd. Book your next ride with us and be a part of the movement towards more sustainable transportation in the luxury industry.</p>
    </div>
    
  </div> */}
  <div className="about-container" >
  <h3 >Sustainability Initiatives</h3>

<div className="career_cards" >
{greenArr.map(k => <CareerCard {...k} />)}
</div>

    {/* <div className="content" >
    <p><span>At Luxury Chauffeur Service Ltd</span>, we are proud to incorporate green initiatives into our luxury transportation offerings. As a premier chauffeur company, we understand the importance of reducing our carbon footprint and preserving the environment for future generations. That's why we are committed to integrating sustainable practices throughout our operations, including offering rides in electric vehicles as part of our diverse fleet.</p>
      <p>By choosing Luxury Chauffeur Service Ltd, you're not just selecting a mode of transportation; you're making a conscious decision to support environmentally friendly practices. While we offer a range of vehicles to suit your needs, including electric options, every ride with us contributes to reducing emissions and promoting cleaner air. Whether you're traveling for business or pleasure, you can rest assured that your journey with us aligns with your values of sustainability without compromising on luxury or comfort.</p>
      <p>Join us in our commitment to a greener future by experiencing the seamless blend of opulence and eco-consciousness with Luxury Chauffeur Service Ltd. Book your next ride with us and be a part of the movement towards more sustainable transportation in the luxury industry.</p>
   
  
    </div> */}
    
    
  </div>
  <Footer />
  </Wrapper>

}

const Wrapper = styled.section`
.hero-contents h1{
  text-align:center;
  max-width:inherit
}

.hero-section{
margin-bottom:0;
    position: relative;
  display: inline-block;
}


.image{
  display: block;
  width: 100%;
  height: auto;
  }

.heading{

  font-weight: bold;
   position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 4rem;
  text-align: center;
}
  @media (max-width: 768px) {
  .heading {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
    padding: 0 10px;   /* Optional: add some padding for better readability */
  }
}



.section-title {
  position: absolute;
  color: white;
  padding: 12px 35px;
    border: none;
    border-radius: 20px;
  background-image: radial-gradient(100% 100% at 100% 0, goldenrod 0, black 100%);
font-weight:bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.career_cards{
  display: flex;
flex-wrap:wrap;

}

.career_card{
  width: 400px;
  flex-grow:1;
    overflow-wrap: break-word;
    ${'' /* text-align: center; */}
    ${'' /* background: mediumpurple; */}
    padding: 20px;

    img{
      width: 100%;
    border-radius: 20px;
    height: 116px;
    }

    h2{
      font-size: 26px;
    margin-top: 14px;
    }

    p{
      margin-top:0 !important;
      font-family: AvenirNext, Helvetica, Arial, Open Sans, sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .15px;
    color: #181a1f;
    font-weight: 200;
    }
}

.form-container button{
  width:100%
}

.form-container{
  margin:20px 0
}

.about-container{
  display: flex;
    -webkit-box-pack: center;
    /* justify-content: center; */
    /* -webkit-box-align: center; */
    align-items: center;
    margin: 20px;
    flex-direction: column;


h3{
  margin:2rem 0 1rem;
  font-weight:bold;
  font-size: clamp(1.75rem, 1.35rem + 1.6vw, 2.75rem);

  }
}

.content{


  p {
  line-height: 26px;
    margin-top: 40px;
    font-size: 14px;
    font-weight: 300;
    font-family: sans-serif;
}

  span{
    font-size:25px;

  }

  

  
}

.content{
  width:60%;
  margin:auto

}




@media (max-width: 1300px) {

  .content{
  width:90%;
  margin:auto

}

.career_cards{
  justify-content:center
}

.connect{
  width:90%;
  margin:20px auto 0
}
  }


`;

export default GreenInitiative;