
import React, { useState, useEffect } from 'react';
import { MdLocationPin } from 'react-icons/md';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const LocationSearchInput = ({origin,setOrigin,label}) => {

  // I am using google map api to fetch location, and i can access all the places even the airports also, but when i use post-code parameter i get only post-codes, is there any way so that i can get both post codes and airports only

  const handleChange = newAddress => {
    setOrigin(newAddress);
  };

  const handleSelect = selectedAddress => {
    setOrigin(selectedAddress)
    console.log(selectedAddress,"selectedAddress");
  };

  return (
    <PlacesAutocomplete
      value={origin}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{componentRestrictions: { country: 'UK' },types:['postal_code','airport'] }}
      // searchOptions={{componentRestrictions: { country: 'UK' }}}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
        <div className='from-cont' >
     
     <MdLocationPin />
       <input
         {...getInputProps({
           placeholder: label,
           className: 'from',
         })}
       />
       </div>
       <div className="autocomplete-dropdown-container">
         {loading && <div>Loading...</div>}
         {suggestions.map(suggestion => {
           const className = suggestion.active
             ? 'suggestion-item--active'
             : 'suggestion-item';
           // inline style for demonstration purpose
           const style = suggestion.active
             ? { backgroundColor: '#fafafa', cursor: 'pointer' }
             : { backgroundColor: '#ffffff', cursor: 'pointer' };
           return (
             <div
               {...getSuggestionItemProps(suggestion, {
                 className,
                 style,
               })}
             >
               <span>{suggestion.description}</span>
             </div>
           );
         })}
       </div>
        </div>
    
        
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
