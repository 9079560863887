import React from "react";
import book from "../images/book.jpg";
import discoverWorld from "../images/discover-world.jpg";
import meetDrivers from "../images/meet-drivers.jpg";
import styled from "styled-components";
import { Image } from "react-bootstrap";

function Booking() {
  const cardArray = [
    {
      id: 1,
      img: book,
      header: "City-to-City rides",
      text: "  Your stress-free solution for traveling between cities, with chauffeurs across the globe.",
    },
    {
      id: 2,
      img: discoverWorld,
      header: "Airport transfers",
      text: "Your stress-free solution for traveling between cities, with chauffeurs across the globe.",
    },
    {
      id: 3,
      img: meetDrivers,
      header: "Hourly and full day hire",
      text: "Your stress-free solution for traveling between cities, with chauffeurs across the globe.",
    },
  ];
  return (
    <Wrapper>
      <Header>DISCOVER THE DIFFERENCE OF BESPOKE TRAVEL</Header>
      <GridDiv>
        {cardArray.map((item) => (
          <Card key={item.id}>
            <Image src={item.img} alt="Card image" />
            <h4>{item.header}</h4>
            <p>{item.text}</p>
          </Card>
        ))}
      </GridDiv>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  max-width: 1280px;
  margin: 2rem auto;
  padding: 2rem;

  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

export default Booking;

const Header = styled.h2`
  text-align: center;
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 2rem;
  gap: 2rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.div`
  img {
    border-radius: 12px;
    height: 200px;
    width: 100%;
  }

  h4 {
    margin: 0;
    margin-top: 1rem;
  }

  p {
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    font-weight: 500;
    margin-top: 8px;
  }
`;
