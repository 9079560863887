import React from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";

function LegaLNotice() {
  const containerStyle = {
    maxWidth: "800px",
    margin: "auto",
    padding: "20px",
    fontFamily: "AvenirNextDemiBold, Helvetica, Arial, Open Sans, sans-serif",
    lineHeight: "1.6",
  };

  const headingStyle = {
    color: "#333",
    fontSize: "32px",
    marginBottom: "10px",
    fontWeight: "600",
  };

  const sectionHeadingStyle = {
    color: "#666",
    fontSize: "20px",
    marginTop: "20px",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    marginBottom: "15px",
    fontSize: "16px",
    color: "rgba(0,0,0,0.6)",
    fontWeight: 500,
  };

  return (
    <div>
      <Navbar />
      <div style={containerStyle}>
        <h2 style={headingStyle}>Legal Notice</h2>

        <p style={paragraphStyle} className="mt-4">
          Luxury Chauffeur Service Ltd <br />
          Clinton Crescebt 88 <br />
          IG6 3AW London <br />
          United Kingdom
        </p>
        <p style={paragraphStyle} className="mt-4">
          Fax: +44 (0) 20 3053 3136 <br />
          Phone: +44 (7) 5 96 53 94 19 <br />
          E-Mail: luxurychauffeurserviceltd@outlook.com
        </p>
        <p style={paragraphStyle} className="mt-4">
          Managing Director: Saad Akhtar
        </p>

        <p style={paragraphStyle} className="mt-4">
          Online dispute resolution in accordance with Art. 14 (1)
          ODR-Regulation: The European Commission provides a platform for online
          dispute resolution (OS) which is accessible at
          http://ec.europa.eu/consumers/odr/
        </p>
        <p style={paragraphStyle} className="mt-4">
          Zero Tolerance Policy
        </p>
        <p style={paragraphStyle} className="mt-4">
        Luxury Chauffeur Service Ltd strictly adheres to the regulations as codified by the
        United Kingdom Department for Transport and as such, has a
        zero-tolerance drug and alcohol policy for all chauffeurs using the
        Luxury Chauffeur Service Ltd platform to provide service. If you suspect a chauffeur is
        under the influence of drugs or alcohol, please ask to end the trip
        immediately, then call 999 or your local non-emergency assistance
        line. Please also report any violations of this policy to Luxury Chauffeur Service Ltd
        (luxurychauffeurserviceltd@outlook.com) as well as the Driver and Vehicle Standards Agency (DVSA) at 0300 123 9000 or via email at enquiries@dvsa.gov.uk. If you want to contact our 24/7 customer care
        hotline: UK +44 20 7946 0824
        </p>
        <p style={paragraphStyle} className="mt-4">
          Luxury Chauffeur Service Ltd provides transportation based solely upon chauffeur
          availability, without regard to geographic departure point or
          destination and without regard to race, color, national origin,
          religious belief or affiliation, sex, sexual orientation, gender
          identity, marital status, disability, age, or any other characteristic
          protected under applicable federal or state law.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default LegaLNotice;
