import { Image } from "react-bootstrap";
import logo from "../images/luxury-chauffeur-logo.png";
import {
  BottomFooterDiv,
  ContactDiv,
  FooterBottomLinks,
  FooterLinks,
  FooterWrapper,
  LeftDiv,
  LinksHeader,
  SecondDiv,
  UseFulLinksDiv,
} from "../styles/footerStyles";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <FooterWrapper>
      <div className="footer-container">
        <div className="flex-div">
          <LeftDiv>
            <Image src={logo} alt="logo" className="img-fluid" />

            <p>
              Simple Way to Reach
              <br /> Your Vacation Destination
            </p>
          </LeftDiv>
          <SecondDiv>
            <LinksHeader>City-to-City Rides</LinksHeader>
            <Link
              style={{ textDecoration: "none" }}
              to={"/transport/London%20to%20Bristol"}
            >
              <FooterLinks>London - Bristol</FooterLinks>
            </Link>

            <Link
              style={{ textDecoration: "none" }}
              to={"/transport/London%20to%20Liverpool"}
            >
              <FooterLinks>London - Liverpool</FooterLinks>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to={"/transport/London%20to%20Birmingham"}
            >
              <FooterLinks>London - Birmingham</FooterLinks>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to={"/transport/London%20to%20Manchester"}
            >
              <FooterLinks>London - Manchester</FooterLinks>
            </Link>
          </SecondDiv>

          <UseFulLinksDiv>
            <LinksHeader>Useful Links</LinksHeader>
            <Link style={{ textDecoration: "none" }} to={"/about"}>
              <FooterLinks>About Us</FooterLinks>
            </Link>
            <Link style={{ textDecoration: "none" }} to={"/green-initiative"}>
              <FooterLinks>Green Initiative</FooterLinks>
            </Link>
            <Link style={{ textDecoration: "none" }} to={"/europe-tour"}>
              <FooterLinks>Europe Tour</FooterLinks>
            </Link>
            <Link style={{ textDecoration: "none" }} to={"/partners"}>
              <FooterLinks>Become A Partner</FooterLinks>
            </Link>
          </UseFulLinksDiv>

          <ContactDiv>
            <LinksHeader>Contact Us</LinksHeader>
            <FooterLinks>London, IG6 3AW, 88 Clinton Crescent, UK</FooterLinks>
            <FooterLinks>luxurychauffeurserviceltd@outlook.com</FooterLinks>
            <FooterLinks>+ 44 020 3053 3136</FooterLinks>
            <FooterLinks>+ 44 75 96 53 94 19</FooterLinks>
          </ContactDiv>
        </div>
        <BottomFooterDiv>
          <div className="left-div">
            <h4 className="copy-rights-text">
              © {new Date().getFullYear()} Luxury Chauffeur
            </h4>
            <Link style={{ textDecoration: "none" }} to={"/terms"}>
              <FooterBottomLinks>Terms</FooterBottomLinks>
            </Link>
            <Link style={{ textDecoration: "none" }} to={"/privacy-policy"}>
              <FooterBottomLinks>Privacy Policy</FooterBottomLinks>
            </Link>
            <Link style={{ textDecoration: "none" }} to={"/legal-notice"}>
              <FooterBottomLinks>Legal Notice</FooterBottomLinks>
            </Link>
            <Link style={{ textDecoration: "none" }} to={"/accessibility"}>
              <FooterBottomLinks>Accessibility</FooterBottomLinks>
            </Link>
          </div>

          <div className="right-div">
            <FaFacebookF
              cursor={"pointer"}
              color="rgba(255,255,255,0.5)"
              fontSize={24}
            />
            <FaInstagram
              cursor={"pointer"}
              color="rgba(255,255,255,0.5)"
              fontSize={24}
            />
            <FaXTwitter
              cursor={"pointer"}
              color="rgba(255,255,255,0.5)"
              fontSize={24}
            />
            <FaLinkedin
              cursor={"pointer"}
              color="rgba(255,255,255,0.5)"
              fontSize={24}
            />
          </div>
        </BottomFooterDiv>
      </div>
    </FooterWrapper>
  );
}

export default Footer;
