import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import styled from "styled-components";
import Navbar from "../component/Navbar";
import PartnerpageHero from "../component/PartnerpageHero";
import image1 from "../images/car.png";
import image2 from "../images/meter.png";
import image3 from "../images/desktop.png";
import image4 from "../images/schedule.png";
import image5 from "../images/clock.png";
import image6 from "../images/lead-way.png";
import image from "../images/partner2.jpeg";
import Footer from "../component/Footer";
import axios from "axios";
import { toast } from "react-toastify";
import { PiChargingStationLight } from "react-icons/pi";
import { IoDiamondOutline } from "react-icons/io5";
import { PiShootingStarThin } from "react-icons/pi";
import { VscCalendar } from "react-icons/vsc";
import { CiRoute } from "react-icons/ci";
import { GiMeshNetwork } from "react-icons/gi";
import "../styles/PartnerPage.css";
import ContactForm from "../component/ContactForm";

function PartnerPage() {
  return (
    <>
      <Navbar fixed={true} />
      <Wrapper>
        <PartnerpageHero />
        <section className="why-section">
          <div className="why-container">
            <div className="whys">
              <div className="why">
                <div className="why-title">
                  <IoDiamondOutline className="diamond" />
                  <h5>Unrivaled Luxury Experience</h5>
                </div>
                <p>
                  Experience luxury like never before with Luxury Chauffeur
                  Service Ltd. Our meticulously maintained fleet and
                  professional chauffeurs ensure an exclusive, sophisticated
                  experience, enhancing your company's reputation.
                </p>
              </div>
              <div className="why">
                <div className="why-title">
                  <PiChargingStationLight className="electric-car" />
                  <h5>
                    Eco-Friendly Transportation Solutions: Choose Sustainability
                  </h5>
                </div>
                <p>
                  Embrace environmentally conscious travel with our fleet of
                  green vehicles. Prioritize sustainability while enjoying
                  luxurious transportation options.
                </p>
              </div>
              <div className="why">
                <div className="why-title">
                  <PiShootingStarThin className="star" />
                  <h5>Professional Excellence</h5>
                </div>
                <p>
                  Our team of expert drivers guarantees professionalism in every
                  journey, from daily commutes to personalized experiences.
                </p>
              </div>
            </div>
            <ContactForm />
            {/* <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="companyName">Company Name</label>
                  <input type="text" className="form-control" id="companyName" placeholder="Company Name" />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail">Email</label>
                  <input type="email" className="form-control" id="inputEmail" placeholder="Email" />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Your query</label>
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
              </div>
              <button type="submit" className="btn btn-primary">Submit</button>
            </form> */}
          </div>
        </section>
        <section className="benefits-hero partnerpage-hero">
          <img src={image} alt="" />
          <div className="benefits-container ">
            <div>
              <h1>Benefits of Working With Us</h1>
              <div className="benefit">
                <VscCalendar className="calendar" />
                <div>
                  <h5>Customizable Solutions</h5>
                  <p>
                    Luxury Chauffeur Service Ltd understands your unique needs,
                    offering customizable solutions. Whether tailoring packages
                    for regular commuting or curating exclusive European tours,
                    our flexibility ensures a perfect fit for your business
                    objectives.
                  </p>
                </div>
              </div>
              <div className="benefit">
                <CiRoute className="route" />
                <div>
                  <h5>Destination Expertise</h5>
                  <p>
                    Collaborating with Luxury Chauffeur Service Ltd offers
                    streamlined transportation solutions. Our precision-driven
                    approach ensures punctuality, reliability, and comfort,
                    providing your clients with unparalleled convenience and
                    peace of mind.
                  </p>
                </div>
              </div>
              <div className="benefit">
                <GiMeshNetwork className="network" />
                <div>
                  <h5>Strategic Networking Opportunities</h5>
                  <p>
                    Elevate your offerings by collaborating with us to craft
                    bespoke, immersive travel adventures that go beyond
                    transportation. Join us in creating unforgettable journeys
                    for your clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  .partnerpage-hero {
    position: relative;
    min-height: calc(100vh - 100px);

    h1 {
      font-size: 56px !important;
      text-transform: capitalize;

      @media (max-width: 992px) {
        font-size: 32px !important;
        width: 100%;
        text-align: center;
      }
    }
  }
  .partnerpage-hero > img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
  }
  .partnerpage-container {
    margin: 0 auto;
    max-width: var(--max-width);
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    div {
      background-color: #fff;
      width: 500px;
      height: 450px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: center;
      box-shadow: 1px -2px 20px 2px #e9e6e6;
      h1 {
        font-size: 2.7rem;
        font-weight: 900;
        line-height: 1.1;
        margin: 10px 0;
        z-index: 1;
      }
      p {
        color: #5e616f;
        font-size: 0.95rem;
      }
      form {
        display: flex;
        column-gap: 30px;
      }
      input {
        padding: 10px 15px;
        border-radius: 5px;
        border: 1px solid #ccc;

        width: 130%;
        text-transform: capitalize;
      }
      position: relative;
    }
  }
  button {
    background-color: var(--border-color);
    border: 0;
    padding: 12px 15px;
    border-radius: 5px;
    width: 100%;
    a {
      color: #fff;
      text-transform: capitalize;
      font-size: 0.9rem;
    }
  }
  .partnerpage-container > div::before {
    width: 150px;
    content: "";
    height: 150px;
    border-radius: 50%;
    border: 70px solid var(--gradient-blue);
    background-color: transparent;
    opacity: 0.1;
    position: absolute;
    top: -23%;
    right: -26%;
  }
  /* why */
  .why-section {
    padding: 0 30px;
    /* height: 80vh; */
    display: flex;
    align-items: center;
  }
  .why-container {
    margin: 50px auto;
    max-width: var(--max-width);
    h2 {
      text-align: center;
      text-transform: capitalize;
      font-size: 3rem;
      margin: 0;
    }
  }
  .why-title {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    h5 {
      font-size: 1rem;
      margin: 0;
    }
  }
  .whys p {
    display: block;
    color: #5e616f;
    font-size: 0.95rem;
  }
  .whys {
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    column-gap: 30px;
  }
  .why {
    padding: 0;
  }
  .why-container button {
    margin: 0px auto;
    width: 20%;
    display: flex;
    justify-content: center;
  }
  /* benefits */
  .benefits-hero > img {
    /* object-fit: contain; */
    height: 450px;
    right: 0;
    left: unset;
  }
  .benefits-hero {
    margin: 60px auto;
    max-width: var(--max-width);
  }
  .benefits-container {
    margin: 0 auto;
    max-width: var(--max-width);
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .benefits-container > div {
    height: auto;

    background-color: #fff;
    width: 600px;

    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    box-shadow: 1px -2px 20px 2px #e9e6e6;
    h1 {
      font-size: 2.4rem;
    }
  }
  .benefit {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    h5 {
      font-size: 1rem;
      margin: 5px 0;
      color: #464953;
    }
    p {
      font-size: 0.95rem;
      margin: 0;
      color: #5e616f;
    }
    margin-bottom: 20px;
  }
  @media (max-width: 877px) {
    .partnerpage-container {
      height: 50vh;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      ${"" /* margin-bottom: 50px; */}

      div {
        width: 80%;
        height: 400px;
        h1 {
          font-size: 2rem;
        }
      }
    }
    .why-container h2 {
      font-size: 2rem;
      margin: 30px 0;
    }
    .why-container {
      margin: 0;
    }
    .whys {
      grid-template-columns: 1fr;
      row-gap: 30px;
    }
    .why-container > button {
      width: 40%;
    }
  }
`;
export default PartnerPage;
