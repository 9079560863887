import React from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import styled from "styled-components";

function PrivacyPolicy() {
  const containerStyle = {
    maxWidth: "800px",
    margin: "auto",
    padding: "20px",
    fontFamily: "AvenirNextDemiBold, Helvetica, Arial, Open Sans, sans-serif",
    lineHeight: "1.6",
  };

  const headingStyle = {
    color: "#333",
    fontSize: "28px",
    marginBottom: "10px",
    fontWeight: "600",
  };

  const sectionHeadingStyle = {
    color: "#666",
    fontSize: "20px",
    marginTop: "20px",
    marginBottom: "10px",
    marginTop: "2rem",
    fontWeight: 600,
  };

  const paragraphStyle = {
    marginBottom: "15px",
  };

  return (
    <div>
      <Navbar />
      <div style={containerStyle}>
        <h2 style={headingStyle}>Privacy Policy and Cookie Policy</h2>

        <h4 style={sectionHeadingStyle}>Privacy Policy</h4>

        <LinksWrapper>
          <Links href="#scope">1. Scope</Links>
          <Links href="#name">
            2. Name and Contact Information of the Controller
          </Links>
          <Links href="#contact">
            3. Contact Information for the Data Protection Officer
          </Links>
          <Links href="#data">4. Data Security</Links>
          <Links href="#provision">5. Provision of the websites</Links>
          <Links href="#cookies">
            6. Cookies, pixels and similar technologies
          </Links>
          <Links href="#special">
            7. Special Features for the Luxury Chauffeur Service Ltd Apps
          </Links>
          <Links href="#social">8. Social Media/Social Networks</Links>
          <Links href="#dataProcessing">
            9. Data Processing During Registered Use of Luxury Chauffeur Service Ltd Services and
            Booking Rides
          </Links>
          <Links href="#payment">10. Payment & Fraud Prevention</Links>
          <Links href="#communication">11. Communication with Luxury Chauffeur Service Ltd</Links>
          <Links href="#email">12. E-mail Advertising, Newsletter</Links>
          <Links href="#involvement">
            13. Involvement of Data Processors by Luxury Chauffeur Service Ltd
          </Links>
          <Links href="#rights">14. Rights of Data Subjects</Links>
          <Links href="#automated">15. Automated decisions</Links>
          <Links href="#dataErasure">
            16. Data Erasure and Storage Duration
          </Links>
          <Links href="#amendment">
            17. Amendment or Update of this Privacy Policy
          </Links>
        </LinksWrapper>
        <h4 style={sectionHeadingStyle}>Cookie Policy</h4>

        <LinksWrapper>
          <Links href="#adjust">1. Adjust</Links>
          <Links href="#adnx">2. Adnxs (AppNexus)</Links>
          <Links href="#attributer">3. Attributer.io</Links>
          <Links href="#bing">4. Bing Ads</Links>
          <Links href="#doubleClick">5. DoubleClick</Links>
          <Links href="#facebook">
            6. Facebook (Custom Audiences und Impressions)
          </Links>
          <Links href="#google">
            7. Google Ads (former Google AdWords Conversion Tracker)
          </Links>
          <Links href="#googleAnalytics">8. Google Analytics</Links>
          <Links href="#googleRemarketing">9. Google Dynamic Remarketing</Links>
          <Links href="#googleFirebase">10. Google Firebase</Links>
          <Links href="#googleFonts">11. Google Fonts</Links>
          <Links href="#googlereCaptcha">12. Google reCAPTCHA</Links>
          <Links href="#googleTag">
            13. Google Tag Manager and Conversion Link
          </Links>
          <Links href="#hotjar">14. Hotjar</Links>
          <Links href="#intercom">15. Intercom</Links>
          <Links href="#linkedIn">16. LinkedIn Marketing Solutions</Links>
          <Links href="#mailchimp">17. Mailchimp</Links>
          <Links href="#mixpanel">18. Mixpanel</Links>
          <Links href="#nexmo">19. Nexmo</Links>
          <Links href="#optimizely">20. Optimizely</Links>
          <Links href="#outbrain">21. Outbrain Pixel / OutBrain Amplify</Links>
          <Links href="#polyfill">22. Polyfill</Links>
          <Links href="#raygun">23. Raygun</Links>
          <Links href="#rudderstack">24. Rudderstack</Links>
          <Links href="#salesforce">25. Salesforce</Links>
          <Links href="#sendgrid">26. Sendgrid</Links>
          <Links href="#snowplow">27. Snowplow</Links>
          <Links href="#twitterAdvertising">28. Twitter Advertising</Links>
          <Links href="#xandr">29. Xandr</Links>
        </LinksWrapper>

        <h3 style={sectionHeadingStyle} id="scope">
          1. Scope
        </h3>

        <p style={paragraphStyle}>
          We, Luxury Chauffeur Service Ltd GmbH ("Luxury Chauffeur Service Ltd"), take the protection of your personal
          data seriously and protect your privacy when processing them in
          accordance with the applicable data protection regulations. <br />{" "}
          <br />
          This privacy policy informs you as a visitor of the Luxury Chauffeur Service Ltd
          websites, as a user or customer of the online platform of Luxury Chauffeur Service Ltd, a
          Luxury Chauffeur Service Ltd App or other services of Luxury Chauffeur Service Ltd (together also "Luxury Chauffeur Service Ltd
          Services") which of your personal data is processed by Luxury Chauffeur Service Ltd and
          for which purpose. The Luxury Chauffeur Service Ltd Services are not aimed at minors.{" "}
          <br /> <br />
          Information on the processing of personal data of chauffeurs can be
          found in the Chauffeur Privacy Policy (e.g. in the BL Chauffeur App).
        </p>

        <h3 style={sectionHeadingStyle} id="name">
          2. Name and Contact Information of the Controller
        </h3>

        <p style={paragraphStyle}>
          The controller for the processing of data within the meaning of the
          General Data Protection Regulation (GDPR) is: <br /> <br />
          Luxury Chauffeur Service Ltd, E-Mail:
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            luxurychauffeurserviceltd@outlook.com
          </span>{" "}
          <br />
          <br />
          You can find further information on Luxury Chauffeur Service Ltd in the{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            Legal Notice
          </span>{" "}
        </p>

        <h3 style={sectionHeadingStyle} id="contact">
          3. Contact Information for the Data Protection Officer
        </h3>

        <p style={paragraphStyle}>
          <br /> You can contact
          our data protection officer and our data protection crew by e-mail to:
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            luxurychauffeurserviceltd@outlook.com
          </span>{" "}
        </p>

        <h3 style={sectionHeadingStyle} id="data">
          4. Data Security
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses appropriate technical and organizational security
          measures to ensure a level of protection for personal data appropriate
          to the risk, taking into account the state of the art, implementation
          costs and the nature, scope, context and purposes of processing as
          well as the risk of varying likelihood and the degree of risk. The
          transfer of personal data between your end device and Luxury Chauffeur Service Ltd is
          generally carried out in an encrypted form (TLS encryption). You can
          identify an encrypted connection for example by the lock symbol in the
          address line of your browser. <br />
          <br />
          If you communicate with us by e-mail, access by third parties cannot
          be ruled out. Luxury Chauffeur Service Ltd also uses transport encryption for e-mails in
          general. But in the case of confidential information, Luxury Chauffeur Service Ltd
          recommends using the postal way or fully encrypted e-mail
          communication (PGP). Please let us know if you would like to
          correspond with us by e-mail in an encrypted form so that we can give
          you information on the relevant addresses and public keys.
        </p>

        <h3 style={sectionHeadingStyle} id="provision">
          5. Provision of the websites
        </h3>

        <p style={paragraphStyle}>
          When visiting Luxury Chauffeur Service Ltd websites for information purposes, i.e. even
          without your registration, data is automatically collected regarding
          the usage through your browser (hereinafter “surf data”). This
          includes your IP address, the status code, the Luxury Chauffeur Service Ltd websites
          visited, date and time of the server request, browser type and browser
          version, referrer (website visited beforehand), files transferred and
          data volume. The surf data is stored by Luxury Chauffeur Service Ltd in so-called log
          files. If you visit Luxury Chauffeur Service Ltd websites without having a Luxury Chauffeur Service Ltd
          account, we will not know who you are. <br /> <br />
          We inform you about the cookies and analysis services used by
          Luxury Chauffeur Service Ltd in section 6. Otherwise, your surf data will not be provided
          to third parties. The processing of surf data is mainly carried out to
          establish and maintain the technical connection when surfing the
          internet. This data is also used by Luxury Chauffeur Service Ltd in a pseudonymized or
          anonymized form in order to analyze the use of our websites, to design
          and improve the Luxury Chauffeur Service Ltd services to meet demand, to recognize and
          eliminate technical or process-related disruptions and problems and to
          prevent illegal use of the Luxury Chauffeur Service Ltd services (e.g. fraudulent
          booking, cyberattacks). <br /> <br />
          Stored log files are erased or anonymized, provided they are no longer
          required to ensure the general functionality of Luxury Chauffeur Service Ltd services.
          Luxury Chauffeur Service Ltd retains the log files only insofar as you have consented to
          this or if there are legal retention obligations. <br /> <br />
          The legal basis for the processing of personal data when providing
          websites is Art. 6 Paragraph 1 lit f GDPR (Luxury Chauffeur Service Ltd’s legitimate
          interest). Insofar you are a Luxury Chauffeur Service Ltd user or customer, the legal
          basis is also Art. 6 para. 1 lit. b DSGVO (contract performance, see
          otherwise below{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            number 9
          </span>
          ). If you have consented to an extended usage of your surf data, the
          legal basis is Art. 6 Paragraph 1 lit. a GDPR (your consent). You can
          revoke your consent at any time.
        </p>

        <h3 style={sectionHeadingStyle} id="cookies">
          6. Cookies, pixels and similar technologies
        </h3>

        <p style={paragraphStyle}>
          When using Luxury Chauffeur Service Ltd services, cookies, pixels or similar methods may
          be used. This is common for most large websites. <br /> <br />
          Cookies are small text files and pixels are small graphic files which
          can be stored on the user's end device. You will find comprehensive
          information on the cookies, pixels and similar methods used by
          Luxury Chauffeur Service Ltd in our{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            Cookie Policy.
          </span>{" "}
          <br /> <br />
          As part of your use of Luxury Chauffeur Service Ltd Services, Luxury Chauffeur Service Ltd sets the cookies
          that are necessary for Luxury Chauffeur Service Ltd to provide a feature you request
          (e.g., language, login status, cookie consent). Luxury Chauffeur Service Ltd also uses,
          where you have consented, its own and third party cookies to analyze
          and improve the use of the Luxury Chauffeur Service Ltd Services, to improve and
          personalize functionality to you, to detect and correct malfunctions
          and problems of a technical or process nature, to prevent unlawful use
          of the Luxury Chauffeur Service Ltd Services (e.g. fraudulent booking, cyber-attacks) and
          for marketing purposes. (including the measurement, analysis and
          evaluation of advertising). The details of this can be found in our
          Cookie Policy. Your consent to the setting and use of cookies by
          Luxury Chauffeur Service Ltd is documented electronically via a Consent Management Tool
          or Consent Management Platform (CMP) used by Luxury Chauffeur Service Ltd. You can revoke
          your consent at any time with future effect or change your cookie
          settings; the easiest way to do this is to change your cookie settings
          as you wish in the CMP used by Luxury Chauffeur Service Ltd. <br /> <br />
          Additionally, you can prevent the storage of cookies and delete
          existing cookies at any time in the settings of your browser. However,
          this may lead to the fact that individual functions of the Luxury Chauffeur Service Ltd
          Services are not or only partially available. The storage period
          varies per cookie and can be viewed in your browser in our{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            Cookie Policy.
          </span>{" "}
          <br /> <br />
          The legal basis for the processing of personal data when using
          cookies, pixels and similar procedures are Art. 6 para. 1 lit. a (your
          consent) and lit. f DSGVO (legitimate interest of Luxury Chauffeur Service Ltd in case of
          necessary cookies in connection with § 25 TTDSG).
        </p>

        <h3 style={sectionHeadingStyle} id="special">
          7. Special Features for the Luxury Chauffeur Service Ltd Apps
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd’s apps are another access point to Luxury Chauffeur Service Ltd services.
          Similar processing of personal data is generally carried out via the
          Luxury Chauffeur Service Ltd apps as via the Luxury Chauffeur Service Ltd websites (see number 5 above) or
          in the case of registered use of the Luxury Chauffeur Service Ltd Services{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            (see number 9).
          </span>{" "}
          <br /> <br />
          When using the Luxury Chauffeur Service Ltd apps, Luxury Chauffeur Service Ltd may also access additional
          data in relation to the app or to your end device, such as e.g. the
          device name, device manufacturer, model, operating system, app or SDK
          version, mode.
          <br /> <br />
          There are also push notifications in the Luxury Chauffeur Service Ltd apps, i.e.
          notifications provided by Luxury Chauffeur Service Ltd in the app of your end device. You
          will only receive push notifications if you have given your consent to
          this on your end device. The legal basis for the data processing is
          Art. 6 Paragraph 1 lit a GDPR (your consent).
          <br /> <br />
          There is a special Luxury Chauffeur Service Ltd App ("BL Chauffeur") for drivers or
          driving service providers working with Luxury Chauffeur Service Ltd. You can find
          information on the processing of personal data carried out by means of
          BL Chauffeur in the
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            Chauffeur Privacy Policy.
          </span>
        </p>

        <h3 style={sectionHeadingStyle} id="social">
          8. Social Media / Social Networks
        </h3>
        <h3 style={sectionHeadingStyle}>
          8.1 Luxury Chauffeur Service Ltd’s Social Media Presence
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd maintains pages in social networks such as Twitter, LinkedIn
          or Facebook. The respective provider of the social network provides
          detailed information about which personal data is processed and how.
          In addition, please see our notes on our pages on the respective
          platforms.
        </p>
        <h3 style={sectionHeadingStyle}>
          8.2 Facebook, Facebook Messenger and Facebook Connect
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses services of the social network, Facebook operated by
          Meta Platforms Inc., 1601 Willow Road, Menlo Park, CA 94025, USA
          ("Facebook"). The controller for the data processing in Europe for
          Facebook is Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
          Harbour, Dublin 2, Ireland. You can access Facebook’s data protection
          information here:
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://www.facebook.com/about/privacy/
          </span>
          <br />
          <br />
          You can contact us and exchange messages with Luxury Chauffeur Service Ltd via Facebook
          Messenger. <br />
          <br />
          Luxury Chauffeur Service Ltd also provides the option of logging in to the Luxury Chauffeur Service Ltd
          account using Facebook Connect. In order to do this, you will be sent
          to a Facebook page where you may log in using your Facebook user
          information. If you log in to the Luxury Chauffeur Service Ltd account using Facebook
          Connect, certain personal data will be transferred by Facebook to
          Luxury Chauffeur Service Ltd. Facebook will inform you regarding what data will be
          transferred, for the purpose of which you may grant or refuse explicit
          consent. Personal data will not be transferred by Facebook to
          Luxury Chauffeur Service Ltd without this consent. Facebook can also determine from your
          login via Facebook Connect that you have logged in with us and when
          you have done so. Luxury Chauffeur Service Ltd uses the data transmitted by Facebook in
          order to identify you. <br />
          <br />
          __If you actively choose to log in via Facebook, you consent to
          Luxury Chauffeur Service Ltd processing, in a personalized form, the data transmitted by
          Facebook to identify you in connection with your account. This consent
          may be revoked at any time without any additional costs and with
          effect for the future. __
          <br />
          <br />
          You also have the option at any time to adjust the usage of Facebook
          Connect and log in to your Luxury Chauffeur Service Ltd account in another manner.
          <br />
          <br />
          Luxury Chauffeur Service Ltd uses, where you have consented to this, Facebook Custom
          Audiences from lists to show you targeted advertising via Facebook.
          Luxury Chauffeur Service Ltd would utilize contact data stored in our CRM system (Braze),
          this data is synced between Braze/Facebook for data security. Once
          synced it is compared with Facebook's existing list of Facebook users.
          The matches are then added to Luxury Chauffeur Service Ltd's Custom Audience. Facebook
          does not collect any new personal data.
          <br />
          <br />
          The legal basis for the processing of personal data when using
          Facebook Connect is in each case Art. 6 Paragraph 1 lit a GDPR (your
          consent).
          <br />
          <br />
          We will inform you in our Cookie Policy about Facebook’s cookies,
          pixels and similar technologies which are used by Luxury Chauffeur Service Ltd.
        </p>

        <h3 style={sectionHeadingStyle}>8.3 Google Customer Match</h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the Customer Match service of Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google"). At
          Google Customer Match, Luxury Chauffeur Service Ltd creates lists in our CRM system
          (Braze) of existing contacts and uploads them to Luxury Chauffeur Service Ltd's Google
          Account. Luxury Chauffeur Service Ltd would utilize contact data stored in Braze, this
          data is synced between Braze/Google for data security. There it is
          compared with existing Google Accounts. The matches are then added to
          the Luxury Chauffeur Service Ltd Audience. On this basis we can display/exclude targeted
          advertising via Google Ads. The legal basis for processing personal
          data when using Google Customer Match is Art. 6 para. 1 lit. a GDPR
          (your consent). We will inform you about cookies and similar
          procedures of Google, which are used by Luxury Chauffeur Service Ltd, in our Cookie
          Policy.
        </p>
        <h3 id="dataProcessing" style={sectionHeadingStyle}>
          9. Data Processing During Registered Use of Luxury Chauffeur Service Ltd Services and
          Booking Rides
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd processes the following personal data provided by you when
          you register and use Luxury Chauffeur Service Ltd Services or when you book rides
          (hereinafter “customer data”). <br />
          <br />
          1. Personal master data (form of address, title, first name, last
          name, company, address, zip code, place, country, password); <br />
          <br /> 2. Contact data (e.g. telephone number, cellphone number,
          e-mail address);
          <br />
          <br /> 3. Contract data (e.g. time and manner of registration, status)
          <br />
          <br /> 4. Ride-related data (e.g. pickup location, destination, times,
          flight number, special requests).
          <br />
          <br /> 5. Status (e.g. bonus program), customer history (e.g. previous
          rides);
          <br />
          <br /> 6. Contract invoicing (e.g. invoices, status, invoicing
          address) and payment data (e.g. last 4 digits of the credit card
          number).
          <br />
          <br />
          The customer data will be used for Luxury Chauffeur Service Ltd services, i.e. for the
          personalized fulfillment of the framework agreement after registration
          (creation, storage, administration and support of your Luxury Chauffeur Service Ltd
          account), for the procurement of booked rides and for the fulfillment
          of the contract of carriage for the benefit of the customer with the
          limousine service provider. Luxury Chauffeur Service Ltd provides customer data to third
          parties, if necessary, in particular to the limousine service
          providers so that the customer can be transported in accordance with
          their booking and the transport can be processed. If the customer has
          indicated a bonus program supported by Luxury Chauffeur Service Ltd, the data required in
          this regard is transmitted to the bonus program provider. The payment
          data collected is stored via a payment service provider and
          transferred to the intermediary financial service provider or bank
          (see Point 10).
          <br />
          <br />
          Personal data can be transmitted to limousine service providers in a
          third-party country outside of the European Union or the European
          Economic Area in which the transport is intended to take place.
          Luxury Chauffeur Service Ltd cannot generally provide further information on the data
          protection level in the third-party country. An adequacy decision (see
          Art. 45 Paragraph 3 GDPR) or suitable guarantees (see Art. 46 GDPR)
          are not required for the transmission since the transmission is
          required for the performance of a contract between the data subject
          and the controller or to carry out pre-contractual measures upon
          request from the data subject (Art. 49 Paragraph 1 lit b GDPR) or
          because the transmission is required to enter into or to perform a
          contract concluded in the interest of the data subject by the
          controller with another natural or legal person (Art. 49 Paragraph 1
          lit c GDPR).
          <br />
          <br />
          Customers have the possibility to rate a ride arranged by Luxury Chauffeur Service Ltd
          e.g. in the Luxury Chauffeur Service Ltd App. Ratings are stored in the customer's
          profile and can be communicated to the respective driving service or
          provider or chauffeur in anonymised form. The customer can view his
          own reviews, other customers will not see these reviews. The purpose
          of the data processing within the evaluation system is the handling of
          the trip or the payment towards the driver or the dispatcher as well
          as the analysis and improvement of the Luxury Chauffeur Service Ltd Services, e.g. to
          monitor the proper execution of trips, to increase the quality and to
          prevent or combat illegal use. Ratings are usually not stored for more
          than 2 years and then deleted or anonymised.
          <br />
          <br />
          The legal basis for the processing of personal data during the
          registered use of Luxury Chauffeur Service Ltd Services and during the booking of trips
          is Art. 6 para. 1 lit. b GDPR (contract performance). If the data
          subject provides additional, voluntary information (e.g. flight
          number, frequent flyer programme, special requests, rating), the legal
          basis is their consent according to Art. 6 Paragraph 1 lit a GDPR and
          our legitimate interest according to Art. 6 Paragraph 1 lit. f GDPR.
          <br />
          <br />
          In addition, Luxury Chauffeur Service Ltd processes customer data in order to analyse the
          use of the Luxury Chauffeur Service Ltd Services, to design and improve them in a
          demand-oriented and personalised manner, to advertise the Luxury Chauffeur Service Ltd
          Services, to detect, limit and eliminate malfunctions and problems of
          a technical or process-related nature and to prevent illegal use of
          the Luxury Chauffeur Service Ltd Services (e.g. fraudulent booking, cyberattacks). In
          this respect, the legal basis for the processing of personal data is
          Art. 6 para. 1 lit. f GDPR (legitimate interest of Luxury Chauffeur Service Ltd). Data
          will not be passed on to recipients in this respect unless to
          Luxury Chauffeur Service Ltd's data processors (see Art. 28 GDPR) or as far as otherwise
          permitted by law.
        </p>

        <h3 id="payment" style={sectionHeadingStyle}>
          10. Payment & Fraud Prevention
        </h3>

        <h3 style={sectionHeadingStyle}>10.1 Payment</h3>

        <p style={paragraphStyle}>
          All Luxury Chauffeur Service Ltd bookings can be paid by credit or debit card. The credit
          card information only has to be stored once upon the first booking and
          is protected against unauthorized access. For this purpose, a
          certified payment provider is used whose systems meet the applicable
          security standards, such as the PCI DSS standard (Payment Card
          Industry Data Security Standard). For recurring transactions, the card
          data is stored with our assigned PCI DSS-certified payment provider.
          In this respect, the legal basis for the processing is Art. 6(1)(b)
          GDPR (performance of contract). <br />
          <br />
          Luxury Chauffeur Service Ltd itself does not store credit card data or only in
          abbreviated form for analysis purposes or to prevent fraud. The legal
          basis for this is Art. 6 Paragraph 1 lit f GDPR (Luxury Chauffeur Service Ltd’s
          legitimate interest). <br /> <br />
          Card payments are provided by the payment service provider Braintree.
          The privacy policy for the use of the Braintree service can be found
          at:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.braintreepayments.com/legal
          </span>
          .<br /> <br />
          Information on new security and verification procedures in connection
          with the so-called Payment Services Directive ("PSD2") can be found at
          our payment service provider Braintree{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            (https://www.braintreepayments.com/de/legal){" "}
          </span>
          .<br /> <br />
          In addition to card payment, you can pay for your bookings via PayPal
          in our mobile apps (Android / iOS). In this case, on the basis of Art.
          6 (1) b) GDPR, personal data such as the e-mail address of your PayPal
          account and information about your mobile device (e.g. Device ID) will
          be transmitted by us to PayPal (Europe) S.à.r.l et Cie, S.C.A., 22-24
          Boulevard Royal, L-2449 Luxembourg, for the purpose of fulfilling the
          contract. For more information on data processing by PayPal, please
          visit:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.paypal.com/de/webapps/mpp/ua/privacy-full
          </span>
        </p>

        <h3 style={sectionHeadingStyle}>10.2 Fraud Prevention</h3>

        <p style={paragraphStyle}>
          To ensure that a payment instrument is used by its rightful owner and
          to prevent fraud, IP addresses, e-mail addresses, payment data and
          card information may be transmitted to one or more external fraud
          prevention service providers. This transmission may also contain
          additional personal data. The external fraud prevention service
          providers process these personal data on behalf of Luxury Chauffeur Service Ltd. The
          legal basis for this processing is Art. 6 para. 1 lit. f GDPR
          (legitimate interest of Luxury Chauffeur Service Ltd). <br />
          <br />
          Luxury Chauffeur Service Ltd currently uses the service provider Ravelin (Ravelin
          Technology Ltd., 5th Floor, 174 - 180 Old Street, London, England,
          EC1V 9BP) for fraud prevention purposes. For more information about
          Ravelin's privacy practices, please visit{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.ravelin.com/privacy-policy
          </span>
          . <br />
          <br />
          In the course of the authentication of the cardholder, it may also be
          necessary for individual transactions that Luxury Chauffeur Service Ltd requires a copy
          of an additional identification document (e.g. identity card,
          passport, drivers’ licence) or a copy of the credit card. Luxury Chauffeur Service Ltd
          will ask you to black out any data that is not required (e.g. the
          credit card number except the last four digits). In this respect, the
          legal basis is Art. 6 para. 1 lit. b GDPR (fulfilment of contract),
          your consent (Art. 6 para. 1 lit. a GDPR) and our legitimate interest
          (Art. 6 para. 1 lit. f GDPR).
        </p>

        <h3 id="communication" style={sectionHeadingStyle}>
          11. Communication with Luxury Chauffeur Service Ltd
        </h3>

        <p style={paragraphStyle}>
          If you contact Luxury Chauffeur Service Ltd (e.g. by phone, contact form, feedback form,
          chat, messenger, e-mail or social media (Facebook, Instagram,
          Twitter)), the data you provide will be processed to handle your
          request and to answer your inquiry. The legal basis is Art. 6 Para. 1
          lit. a GDPR (consent) and Art. 6 Para. 1 lit. b GDPR (fulfilment of
          contract and initiation of contract). <br />
          <br />
          Contact data is also used by Luxury Chauffeur Service Ltd - pseudonymised or anonymised
          if possible - to design and improve Luxury Chauffeur Service Ltd services according to
          your needs, to identify and eliminate malfunctions and problems of a
          technical or process-related nature and to prevent illegal use of
          Luxury Chauffeur Service Ltd services (e.g. fraudulent booking, cyberattacks). In this
          respect, the legal basis for the processing of personal data is Art. 6
          para. 1 letter f GDPR (legitimate interest of Luxury Chauffeur Service Ltd).
          <br />
          <br />
          Luxury Chauffeur Service Ltd also uses external services and tools, e.g. messenger
          services or chat support tools, to communicate with the customer. The
          legal basis for the processing of personal data is Art. 6 para. 1 lit.
          f GDPR (legitimate interest of Luxury Chauffeur Service Ltd). Agreements on commissioned
          data processing exist with the respective providers - where necessary.
          <br />
          <br />
          In this context, we use Intercom as a communication medium, either by
          e-mail or by messages within our products. The Intercom Messenger apps
          and apps in inbox products can also give you access to other
          third-party applications such as Stripe. You should consult the
          privacy notices of these third parties for more information about how
          they use your personal information. As part of our service agreements,
          Intercom collects publicly available contact and social information
          about you, such as your email address, gender, company, job title,
          photos, website URLs, social networking sites and physical addresses,
          to improve your user experience. For more information about Intercom's
          privacy practices, please visit
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.intercom.com/terms-and-policies#privacy
          </span>
          .<br />
          <br />
          Luxury Chauffeur Service Ltd also offers customers different feedback channels, also via
          external platforms. Such customer feedback is also used to process
          your request and also - if possible pseudonymized or anonymized - to
          design and improve Luxury Chauffeur Service Ltd Services according to your needs, to
          recognize and eliminate malfunctions and problems of a technical or
          process-related nature and to prevent illegal use of Luxury Chauffeur Service Ltd
          Services (e.g. fraudulent booking, cyberattacks). The legal basis for
          the data processing is, if you have separately and explicitly agreed,
          Art. 6 para. 1 lit. a GDPR (consent) and otherwise Art. 6 para. 1 lit.
          f GDPR (legitimate interest of Luxury Chauffeur Service Ltd). With the respective
          providers of feedback tools there are again - as far as necessary -
          agreements on commissioned data processing. The respective feedback
          tool may contain additional information about which data is collected
          and in which way data is processed.
        </p>

        <h3 id="email" style={sectionHeadingStyle}>
          12. E-mail Advertising, Newsletter
        </h3>

        <p style={paragraphStyle}>
          If you have agreed to receiving advertising or if Luxury Chauffeur Service Ltd otherwise
          has the right, we will use your customer data to send you personalized
          advertising or general newsletters. The following data is mainly
          affected: Form of address, name, e-mail address. The purpose of the
          data processing is for Luxury Chauffeur Service Ltd to inform you regarding current
          offers and to draw attention to features of Luxury Chauffeur Service Ltd services. <br />
          <br />
          E-mail advertising and newsletters may contain pixels. In this case, a
          graphic file is inserted into the e-mail sent in HTML format, based on
          which a statistical evaluation may be carried out. By using pixels,
          Luxury Chauffeur Service Ltd can detect whether and when e-mails have been opened and
          links contained therein clicked. The legal base for the data
          processing is, insofar as you have separately and expressly consented,
          Art. 6 Paragraph 1 lit a GDPR (consent) and otherwise Art. 6 Paragraph
          1 lit f GDPR (Luxury Chauffeur Service Ltd’s legitimate interest) in conjunction with
          Section 7 Paragraph 3 of the German Act Against Unfair Competition
          (UWG).
          <br />
          <br />
          Luxury Chauffeur Service Ltd has the right, in context of legal permission according to
          Section 7 Paragraph 3 UWG, to use the e-mail address, which you have
          provided regarding a chargeable booking, to directly advertise its
          own, similar products or services. If you do not wish to receive
          advertising from Luxury Chauffeur Service Ltd for similar products or services, you may
          at any time revoke the corresponding use of your e-mail address
          without incurring any costs other than the transmission costs in
          accordance with the base tariffs. To this end, you can use the
          unsubscribe link contained in any mail or you can write an e-mail to
          us using the above-mentioned e-mail addresses (see section 2).
        </p>

        <h3 id="involvement " style={sectionHeadingStyle}>
          13. Involvement of Data Processors by Luxury Chauffeur Service Ltd
        </h3>

        <p style={paragraphStyle}>
          As far as Luxury Chauffeur Service Ltd involves third parties in its data processing,
          e.g. technical service providers or other Luxury Chauffeur Service Ltd subsidiaries, this
          is always done on behalf of Luxury Chauffeur Service Ltd and only if these processors
          offer sufficient guarantees that suitable technical and organisational
          measures are carried out in such a way that the processing is in
          accordance with the data protection requirements, in particular Art.
          28 GDPR, and guarantees the protection of the rights of the data
          subject. If processors are located in third countries, the data
          protection requirements for the transfer pursuant to Art. 44 et seq.
          GDPR are complied with in each case. As a rule, the appropriate
          guarantees in third countries are established by means of an adequacy
          decision (Art. 45 para. 3 GDPR) or the agreement of standard
          contractual clauses (see Art. 46 para. 2 c) GDPR in conjunction with
          Art. 93 para. 2 GDPR).
        </p>

        <h3 id="rights" style={sectionHeadingStyle}>
          14 Rights of Data Subjects
        </h3>

        <p style={paragraphStyle}>
          If your personal data is processed by Luxury Chauffeur Service Ltd, you are the data
          subject (Art. 4 No. 1 GDPR). As the data subject, you have the
          following rights in relation to the personal data affecting you:
        </p>
        <h3 style={sectionHeadingStyle}>
          14.1 Right to information (Art. 15 GDPR)
        </h3>

        <p style={paragraphStyle}>
          The data subject has the right to obtain a confirmation from the
          controller as to whether personal data is processed; if this is the
          case, they have a right of information about this personal data and
          further information on the data processing.
        </p>
        <h3 style={sectionHeadingStyle}>
          14.2 Right to rectification (Art. 16 GDPR)
        </h3>

        <p style={paragraphStyle}>
          The data subject has the right to obtain from the controller without
          undue delay the rectification or completion of inaccurate personal
          data.
        </p>
        <h3 style={sectionHeadingStyle}>
          14.3 Right to erasure (Art. 17 GDPR)
        </h3>

        <p style={paragraphStyle}>
          The data subject has the right to demand from the controller the
          erasure of personal data without undue delay and the controller is
          obliged to erase personal data without undue delay, provided the data
          is no longer required, the data subject revokes their consent or
          lodges an objection to the processing, the personal data was processed
          unlawfully or there is otherwise a ground for erasure within the
          meaning of Art. 17 GDPR and the controller does not have the right to
          object to erasure.
        </p>
        <h3 style={sectionHeadingStyle}>
          14.4 Right to the restriction of data processing (Art. 18 GDPR)
        </h3>

        <p style={paragraphStyle}>
          The data subject has the right to demand from the controller the
          restriction of processing when one of the conditions mentioned in Art.
          18 GDPR applies, namely the accuracy of the personal data is contested
          by the data subject or the processing is unlawful and the data subject
          opposes the erasure of the personal data.
        </p>
        <h3 style={sectionHeadingStyle}>
          14.5 Right to objection (Art. 21 GDPR)
        </h3>

        <p style={paragraphStyle}>
          Insofar as the data processing is based on a legitimate interest from
          our side (Art. 6 Paragraph 1 lit. f GDPR) or is direct advertising,
          the data subject has at any time the right to lodge an objection to
          the processing of personal data affecting them for the reasons
          mentioned in Art. 21 GDPR. The controller will then no longer process
          the personal data, unless they can demonstrate compelling legitimate
          grounds for the processing which override the interests, rights and
          freedoms of the data subject or the processing serves for the
          establishment, exercise or defense of legal claims.
        </p>
        <h3 style={sectionHeadingStyle}>
          14.6 Right to data portability (Art. 20 GDPR)
        </h3>

        <p style={paragraphStyle}>
          The data subject has the right within the meaning of Art. 20 GDPR to
          receive the personal data, which they have provided to a controller,
          in a structured, commonly used and machine-readable format and have
          the right to transmit this data to another controller without
          hindrance from the controller to which the personal data has been
          provided.
        </p>
        <h3 style={sectionHeadingStyle}>
          14.7 Right to lodge a complaint (Art. 77 GDPR)
        </h3>

        <p style={paragraphStyle}>
          Without prejudice to any other administrative or judicial remedy,
          every data subject has the right to lodge a complaint with a
          supervisory authority according to Art. 77 GDPR.
        </p>

        <h3 style={sectionHeadingStyle}>
          14.8 Revocation of consent (Art. 7 para. 4 GDPR)
        </h3>

        <p style={paragraphStyle}>
          If the data processing is based on the consent of a data subject, the
          data subject has the right to revoke his or her consent at any time.
          You can do this by sending an e-mail to: dataprotection@Luxury Chauffeur Service Ltd.com.
          The revocation of consent shall not affect the lawfulness of the
          processing carried out on the basis of the consent until the
          revocation.
        </p>
        <h3 id="automated" style={sectionHeadingStyle}>
          15. Automated decisions
        </h3>

        <p style={paragraphStyle}>
          In the case of Luxury Chauffeur Service Ltd, you are only subject to an automated
          decision process (see Art. 22 GDPR) in exceptional cases if you
          re-enter a payment method via which a payment has already previously
          failed or when current indications justify the suspicion that it is a
          fraudulent booking. In these cases, your request to book a ride with
          Luxury Chauffeur Service Ltd will be refused. Such an automatic decision is required to
          conclude the contract (Art. 22 Paragraph 2 lit a GDPR). The data
          subject has the option of contacting us using the mentioned contact
          data (see section 2) in order to have an explanation or an
          intervention by a person or to express their point of view.
        </p>
        <h3 id="dataErasure" style={sectionHeadingStyle}>
          16. Data Erasure and Storage Duration
        </h3>

        <p style={paragraphStyle}>
          We will erase your personal data as soon as the legal basis for its
          processing lapses. However, legal bases may also exist in parallel or
          a new one may intervene with the lapsing of a legal basis, such as for
          example the duty to store determined data to fulfill a legal retention
          obligation (e.g. according to commercial or tax law).
        </p>
        <h3 id="amendment" style={sectionHeadingStyle}>
          17. Amendment or Update of this Privacy Policy
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd reserves the right to update or amend this privacy policy at
          any time without giving reasons, as far as this should become
          necessary, e. g. due to developments in legislation, jurisdiction or
          regulatory actions or due to further technical developments. <br />
          <br />
          Issued: March 23, 2022.
        </p>

        <h3 style={sectionHeadingStyle}>Luxury Chauffeur Service Ltd Cookie Policy</h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses cookies, pixels or similar technologies (e.g. by means
          of tags, web beacons or gifs). These are small files which are stored
          on your end device so that we can recognize you or collect information
          better when you use Luxury Chauffeur Service Ltd websites or a Luxury Chauffeur Service Ltd app. <br />
          <br />
          Cookies may be distinguished according to their operating life between
          session cookies, which are deleted automatically after ending the
          browser session or permanent cookies, which are stored beyond the
          individual browser session and enable subsequent recognition of the
          end device. A distinction is also made between Luxury Chauffeur Service Ltd’s own cookies
          and those of third parties.
          <br />
          <br />
          You may prevent the storage of cookies at any time using your browser
          settings or delete any cookies present. However, this may mean that
          some functions of Luxury Chauffeur Service Ltd services may not be available or available
          only to a limited extent. The storage duration is different depending
          on the cookie and can be inspected in your browser.
          <br />
          <br />
          The legal basis for the processing of personal data when using
          cookies, pixels and similar technologies is Art. 6 Paragraph 1 lit a
          (consent) and lit f GDPR (Luxury Chauffeur Service Ltd’s legitimate interest).
          <br />
          <br />
          In the context of your visit, Luxury Chauffeur Service Ltd will set the required cookies,
          which are technically necessary, to improve functionality or make the
          use of Luxury Chauffeur Service Ltd services more user-friendly. This concerns, for
          example,
          <br />
          <br />
          1. Your identification and authentication, <br />
          <br /> 2. Storage and user preferences and settings (e.g. language
          settings), which you have made, <br />
          <br /> 3. The inclusion of information already provided so that
          repeated input of information is not necessary, <br />
          <br /> 4. Security-related cookies or <br />
          <br /> 5. Cookies for using multimedia players <br />
          <br /> 6. Luxury Chauffeur Service Ltd also uses its own cookies in order to analyze and
          improve the usage of Luxury Chauffeur Service Ltd services, to detect and remedy
          interruptions and problems of a technical or process-related nature
          and to prevent illegal <br />
          <br /> 7. Usage of Luxury Chauffeur Service Ltd services (e.g. fraudulent booking,
          cyberattacks). Evaluations by Luxury Chauffeur Service Ltd take place in pseudonymized or
          anonymized form as far as is possible.
          <br />
          <br />
          Additionally, Luxury Chauffeur Service Ltd uses its own cookies, pixels or similar
          technologies or those originating from advertising partners (e.g.
          search engine providers, advertising networks or distribution
          partners) in order to improve Luxury Chauffeur Service Ltd services or to measure,
          evaluate, design and improve advertising measures. Luxury Chauffeur Service Ltd uses
          different analysis tools in this regard. In this respect, an
          evaluation by Luxury Chauffeur Service Ltd takes place in pseudonymized or anonymized
          form as far as is possible.
          <br />
          <br />
          The following cookies, pixels and similar technologies may be used in
          the context of Luxury Chauffeur Service Ltd’s services:
        </p>

        <h3 id="bing" style={sectionHeadingStyle}>
          Bing Ads
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the online advertising program, Bing Ads and as part of
          Bing Ads, Bings Ads Conversion Tracking from the Microsoft
          Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA
          (“Microsoft”). <br /> <br />
          In the case of Bing Ads, cookies are set on your computer if you
          access our website via a Microsoft Bing advert. We and Microsoft Bing
          can thereby recognize that someone has clicked on an advert, was
          directed to our website and reached a previously-determined conversion
          page. We only find out the total number of users who have clicked on a
          Bing advert and were then taken to the conversion page. This tool
          allows us to determine the effectiveness of our advertisements.
          According to Microsoft, no personal information concerning the
          identity of the user is disclosed.
          <br /> <br />
          If you would not like to participate in tracking, you may block its
          usage by deactivating the cookie for conversion tracking in your
          internet browser in the user settings.
          <br /> <br />
          You can find further information concerning Bing Ads and the relevant
          data processing using the following internet addresses.{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://advertise.bingads.microsoft.com/en-us/resources/policies/user-safety-and-privacy-policies
            https://privacy.microsoft.com/de-de/privacystatement
          </span>
        </p>
        <h3 id="doubleClick" style={sectionHeadingStyle}>
          DoubleClick
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses DoubleClick by Google, a service from Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google"). <br />
          <br />
          DoubleClick by Google uses cookies to present to you adverts that are
          relevant for you. If you visit our website, a pseudonymous
          identification number (ID) is assigned to your browser in the form of
          cookies to verify which adverts were displayed in your browser and
          which adverts were accessed. The cookies do not contain personal
          information. The use of the DoubleClick cookies allows Google and its
          partner websites only to place adverts based on previous visits to our
          or to other websites on the internet (so-called retargeting). The
          information generated by the cookies is transferred by Google for
          evaluation to a server in the USA and stored there. Data is
          transferred by Google to third parties only based on legal regulations
          or as part of contract data processing. Google will not combine your
          data with other data collected by Google.
          <br />
          <br />
          If you do not agree with the storage of cookies, you can prevent this
          by changing the settings in your browser software or deactivating
          DoubleClick cookies using the following link (“opt out”):
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            http://optout.aboutads.info/?c=2#!/
          </span>
          <br />
          <br />
          You can find additional information concerning Google’s data
          protection provisions using the internet addresses below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            http://www.google.de/policies/privacy{" "}
          </span>{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://www.cookiechoices.org/{" "}
          </span>
        </p>
        <h3 id="facebook" style={sectionHeadingStyle}>
          Facebook (Custom Audiences und Impressions)
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the service, Facebook Custom Audiences and Facebook
          Impressions operated by Facebook Inc., 1601 Willow Road, Menlo Park,
          CA 94025, USA ("Facebook"). The controller for the data processing in
          Europe for Facebook is Facebook Ireland Ltd., 4 Grand Canal Square,
          Grand Canal Harbour, Dublin 2, Ireland. <br /> <br />
          If you visit our websites, you will be recognized via the use of
          cookies. It therefore can be measured when and how often you return
          and use Luxury Chauffeur Service Ltd. Based on this information, we can display
          advertising to you in a targeted manner via Facebook. The data
          collected will be stored and processed in the USA. Facebook
          Impressions allows us to measure how often adverts appear on your
          screen. We can therefore draw conclusions concerning the effectiveness
          of our advertising measures.
          <br />
          <br />
          You can find additional information concerning Facebook’s data
          protection provisions using the internet addresses below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.facebook.com/about/privacy/
          </span>{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.facebook.com/policy.php{" "}
          </span>{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://www.facebook.com/policies/cookies
          </span>
          <br />
          <br />
          If you do not want Facebook to target you with advertising, you can
          adjust the cookie settings in your browser accordingly
        </p>
        <h3 id="google" style={sectionHeadingStyle}>
          Google Ads (former Google AdWords Conversion Tracker)
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the online advertising program “Google AdWords” and as
          part of Google AdWords, the conversion tracking of Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).
          <br />
          <br />
          We use the Google AdWords service to bring attention to our services
          with the help of adverts (so-called Google AdWords) on external
          websites. The cookie for conversion tracking is placed when a user
          clicks on an AdWords advert placed by Google. Cookies are small text
          files which are stored on your computer system. These cookies
          generally become invalid after 30 days and do not allow personal
          identification. If you visited a certain site and if the cookie has
          not yet expired, we and Google can recognize that you have clicked on
          the advert and were taken to this site. Every Google AdWords customer
          receives a different cookie. Cookies can therefore not be tracked via
          the websites of AdWords customers. The collected information by use of
          the conversion cookie serves to generate conversion statistics for
          AdWords customers who have opted for conversion tracking. The
          customers find out the total number of users who have clicked on their
          advert and were taken to a page provided with a conversion tracking
          tag. However, they do not receive any information by means of which
          users can be personally identified. The data collected will be stored
          and processed in the USA.
          <br />
          <br />
          If you would not like to participate in tracking, you may block its
          usage by deactivating the Google conversion tracking cookie in your
          internet browser under user settings. You will then not be included in
          the conversion tracking statistics.
          <br />
          <br />
          You can find additional information concerning Google’s data
          protection provisions using the internet addresses below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            http://www.google.de/policies/privacy{" "}
          </span>{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.cookiechoices.org/
          </span>
        </p>
        <h3 id="googleAnalytics" style={sectionHeadingStyle}>
          Google Analytics
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses Google Analytics, a web analysis service from Google
          LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
          ("Google"). Google Analytics uses cookies which are stored on your
          computer and which enable an analysis of your usage of the website.
          The information generated by the cookie concerning your usage of this
          website (including the abbreviated IP address) is generally
          transferred to a Google server in the US and stored there. Luxury Chauffeur Service Ltd
          uses Google Analytics exclusively with the extension “_anonymizeIp()”
          which ensures anonymization of the IP address by abbreviation and
          excludes direct reference to persons. Using this extension, Google
          will abbreviate your IP address beforehand within Member States of the
          European Union or within other signatories to the agreement of the
          European Economic Area. Google will use this information on our behalf
          to evaluate your usage of the website to compile reports concerning
          the website activities and to render other services to us, which are
          associated with the website usage and the internet usage. The IP
          address transmitted by your browser in the context of Google Analytics
          is not associated with other Google data. If you do not agree with the
          use of Google Analytics, the cookie settings in your browser can be
          adapted accordingly or the functions deactivated via{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://tools.google.com/dlpage/gaoptout.
          </span>
          You can find additional information concerning Google’s data
          protection provisions using the internet addresses below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            http://www.google.de/policies/privacy{" "}
          </span>{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://www.cookiechoices.org/
          </span>
        </p>
        <h3 id="googleRemarketing" style={sectionHeadingStyle}>
          Google Dynamic Remarketing
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the service, Google Dynamic Remarketing from Google
          LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
          ("Google"). <br />
          <br />
          When you visit our website, an ID in the form of a cookie will be
          stored on your device, which allows you to be recognized when you
          access a website which belongs to the Google advertising network. Ad
          banners may be displayed to you on these websites which relate to
          previously viewed content. Google does not collect personal data
          during this process. The data collected will be stored and processed
          in the USA.
          <br />
          <br />
          If you do not agree with Remarketing, you can adapt the settings for
          cookies in your browser or deactivate the function at{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://adssettings.google.com/.
          </span>
          <br />
          <br />
          You can find additional information concerning Google’s data
          protection provisions using the internet addresses below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            http://www.google.de/policies/privacy{" "}
          </span>{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.cookiechoices.org/{" "}
          </span>
          <br />
          <br />
        </p>
        <h3 id="googleFirebase" style={sectionHeadingStyle}>
          Google Firebase
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the platform, Google Firebase from Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google"). <br />
          <br />
          Google Firebase is a web app and mobile app development platform
          which, in addition to development services not relevant to data
          protection, also provides services relevant to data protection such as
          analysis tools and interest-based advertising.
          <br />
          <br />
          When you visit our website on a tablet or smartphone, cookies and
          related technologies are used to enable part of the services provided
          by Firebase. The data volume primarily results from measured user
          behaviors on the website and technical data. The data collected will
          be stored and processed in the US.
          <br />
          <br />
          If you do not agree with the collection of data by Google Firebase,
          you can adapt the cookie settings in your browser accordingly.
          <br />
          <br />
          You can find additional information concerning Google and Firebase
          data protection provisions using the internet addresses below. The
          last link includes a table with the explicit data processing measures:
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            http://www.google.de/policies/privacy{" "}
          </span>
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.cookiechoices.org/
          </span>
        </p>
        <h3 id="googleFonts" style={sectionHeadingStyle}>
          Google Fonts
        </h3>

        <p style={paragraphStyle}>
          In order to display fonts, Luxury Chauffeur Service Ltd uses Web Fonts from Google LLC,
          1600 Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google").{" "}
          <br />
          <br />
          When you access a website, you browser loads the required web fonts in
          your browser to correctly display the text and fonts. To this end,
          your browser connects to Google whereby Google finds out that our
          websites were accessed via your IP address.
          <br />
          <br />
          Google Fonts are used in the interest of consistent and appealing
          display of the Luxury Chauffeur Service Ltd websites. If your browser does not support
          Google Fonts or Web Fonts, a standard font will be used by your end
          device.
          <br />
          <br />
          You can find additional information concerning Google Fonts and
          Google’s data protection provisions using the internet addresses
          below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://fonts.google.com/{" "}
          </span>{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            http://www.google.de/policies/privacy
          </span>
        </p>
        <h3 id="googlereCAPTCHA" style={sectionHeadingStyle}>
          Google reCAPTCHA
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the service, Google reCAPTCHA from Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google"). <br />
          <br />
          Google reCAPTCHA serves to determine, during the booking process of a
          ride, whether the booking was made by a human or whether the supposed
          customer is an improperly used computer (“Bot”). Google makes this
          distinction using a small test, so-called reCAPTCHA (__C__ompletely
          __A__utomated __P__ublic __T__uring test to tell __C__omputers and
          __H__umans __A__part). In order to confirm a booking, you must for
          example check a box or sort images according to a certain rule. It can
          then be discerned whether the visitor is a computer or a human, Google
          uses data such as IP address, browser, screen resolution, click
          behavior and, if necessary, other required data (e.g. cookies). The
          collected data is transferred to a Google server in the US for
          processing.
          <br />
          <br />
          If you do not agree with the storage of cookies, you can adapt the
          cookie settings in your browser accordingly.
          <br />
          <br />
          You can find additional information concerning Google’s data
          protection provisions using the internet addresses below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            http://www.google.de/policies/privacy
          </span>{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://www.cookiechoices.org/
          </span>
        </p>
        <h3 id="googleTag" style={sectionHeadingStyle}>
          Google Tag Manager and Conversion Link
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses Google Tag Manager, a tool from Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”) to
          manage website tags via an interface. <br />
          <br />
          The Google Tag Manager implements tags or markers. The Google Tag
          Manager triggers other tags, which in turn may collect data.
          <br />
          You can find more information about Google's privacy policy at the
          following internet addresses:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            http://www.google.de/policies/privacy{" "}
          </span>{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://policies.google.com/technologies/product-privacy?hl=de
          </span>
          <br />
          <br />
          The tag "conversion link" is used to store click data in own cookies
          which are linked to Luxury Chauffeur Service Ltd domains. Conversions, i.e. planned
          behaviour, can be recorded by this function. Further information can
          be found here:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://support.google.com/tagmanager/answer/7549390?hl=de
          </span>
        </p>
        <h3 id="hotjar" style={sectionHeadingStyle}>
          Hotjar
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the analysis tool Hotjar from Hotjar Ltd, , Level 2, St
          Julians Business Centre, 3, Elia Zammit Street, St Julians STJ 1000,
          Malta (“Hotjar”). <br /> <br />
          When you visit our website, in addition to technical master data and
          cookies, user mouse movements are in particular assessed to create
          so-called “heat maps”. This information allows Luxury Chauffeur Service Ltd to design the
          website to be faster and more customer-friendly. Personal data is
          hidden in this process and thus not collected separately.
          <br /> <br />
          If you do not agree with the approach of Hotjar, the cookie settings
          in your browser can be adapted accordingly or the functions
          deactivated via{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.hotjar.com/legal/compliance/opt-out
          </span>
          .<br /> <br />
          You can find additional information concerning Hotjar’s data
          protection provisions using the internet address below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.hotjar.com/legal/policies/privacy
          </span>
        </p>
        <h3 id="intercom" style={sectionHeadingStyle}>
          Intercom
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses services of Intercom, Inc., 55 2nd Street, 4th Fl., San
          Francisco, CA 94105, USA, to better understand your use of our
          services. <br /> <br />
          We provide Intercom, Inc. with a limited amount of your information
          (e.g., registration date and some personal information such as your
          e-mail address). We use Intercom to collect data for analysis purposes
          when you visit our website or use our product. As a data processor,
          Intercom, at our direction, analyses your use of our website and/or
          product and tracks our relationship through cookies and similar
          technologies so that we can improve our service to you. For further
          information about the use of cookies by Intercom, please visit{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.intercom.com/terms-and-policies#cookie-policy
          </span>
          . We can also use Intercom as a communication medium, either by e-mail
          or by messages within our products. The Intercom Messenger apps and
          apps in inbox products can also give you access to other third-party
          applications such as Stripe. You should consult the privacy notices of
          these third parties for more information about how they use your
          personal information. As part of our service agreements, Intercom
          collects publicly available contact and social information about you,
          such as your email address, gender, company, job title, photos,
          website URLs, social networking sites and physical addresses, to
          improve your user experience. For more information about Intercom's
          privacy practices, please visit{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://www.intercom.com/terms-and-policies#privacy
          </span>
          .<br /> <br />
          The services of Intercom are subject to the Terms of Use of Intercom,
          which can be found at{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.intercom.com/terms-and-policies#terms
          </span>
          . If you wish to opt-out of having this information collected or
          transmitted by Intercom, please contact us.
        </p>
        <h3 id="linkedIn" style={sectionHeadingStyle}>
          LinkedIn Marketing Solutions
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the service LinkedIn Marketing Solutions operated by
          the LinkedIn Corporation., 2029 Stierlin Court, Mountain View, CA
          94043, USA (“LinkedIn”). <br /> <br />
          When you visit our website, an ID in the form of a cookie is stored on
          the device of the user. This ID allows you to be recognized when using
          the website and collects certain usage data. If you then visit
          LinkedIn, advertising is displayed to you based on the collected data.
          The data stored and processed in the USA does not make you
          identifiable as a person.
          <br /> <br />
          If you do not want to participate in tracking, you can deactivate the
          storage of LinkedIn cookies in your browser setting or via{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
          </span>
          <br /> <br />
          You can find additional information concerning LinkedIn’s data
          protection provisions using the internet address below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.linkedin.com/legal/privacy-policy?_l=de_DE{" "}
          </span>{" "}
        </p>

        <h3 id="mailChimp" style={sectionHeadingStyle}>
          MailChimp
        </h3>

        <p style={paragraphStyle}>
          In order to send e-mails, Luxury Chauffeur Service Ltd uses the e-mail service MailChimp
          provided by The Rocket Science Group LLC, 675 Ponce de Leon Avenue NE,
          Suite 5000, Atlanta, GA 30308, USA (“MailChimp”). <br />
          <br />
          If you register for our newsletter or provide us with your e-mail
          address as part of registration, the data required for such purpose
          (e-mail address, language, country) will be transmitted to MailChimp
          in the USA and stored there. In addition to simply sending e-mails,
          MailChimp provides different analysis options to inform us of whether,
          when and where the distributed emails are opened, used or rejected. To
          this end, MailChimp uses, amongst other things, cookies and similar
          technologies.
          <br />
          <br />
          If you do not agree with receiving e-mails and newsletters, you can
          unsubscribe at any time using the “unsubscribe” button which is
          present in every communication. You may refuse the use of cookies in
          your browser settings.
          <br />
          <br />
          You can find additional information concerning MailChimp’s data
          protection provisions using the internet addresses below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://mailchimp.com/legal/privacy/{" "}
          </span>
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://mailchimp.com/legal/cookies/
          </span>
        </p>
        <h3 id="mixpanel" style={sectionHeadingStyle}>
          Mixpanel
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the services of Mixpanel Inc., 405 Howard Street, Floor
          2, San Francisco, CA 94105, USA (“Mixpanel”). <br />
          <br />
          Mixpanel uses cookies or similar technologies to automatically collect
          certain information. The aim of this approach is for example to
          optimize web services, marketing activities and customer experiences
          and to filter out the popularity of individual website areas.
          <br />
          <br />
          Mixpanel also uses pixel tags which are also designated as clear gifs,
          web beacons or web bugs. In this case, they are small graphic images
          with a clear identifier, similar to cookies, which are used to track
          online movements of web users. Unlike cookies, pixel tags are
          invisibly embedded into websites and also allow Mixpanel to send
          e-mails and determine whether these e-mails were opened. It is thus
          intended to ensure that only messages are sent which are of interest
          to users. Mixpanel does not link any pixel tag information with
          personal user data.
          <br />
          <br />
          Additionally, Mixpanel can also record non-personal data (e.g.
          information concerning the device type used) of the user’s mobile
          devices when a corresponding application is downloaded.
          <br />
          <br />
          Mixpanel uses interest-related advertising which can be turned off by
          users at{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            http://preferences-mgr.truste.com/.{" "}
          </span>
          The data collection by cookies can be deactivated by adapting your
          browser’s cookie settings accordingly or{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            at https://mixpanel.com/optout/.
          </span>
          <br />
          <br />
          You can find more information in the Mixpanel privacy policy:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://mixpanel.com/legal/privacy-policy/
          </span>
        </p>
        <h3 id="nexmo" style={sectionHeadingStyle}>
          Nexmo
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the communication tool Nexmo with the data protection
          contact “Vonage Limited”, 15 Bonhill Street, 3rd Floor London, England
          EC2A 4DN, Great Britain. Nexmo offers a communications solution for
          the provision of end-to-end communication via voice, video, text
          (especially SMS), mobile applications and collaboration tools. This
          also includes various analysis options. For more information, please
          refer to the Nexmo or Vonage privacy policy:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.vonage.com/privacy-policy
          </span>
        </p>

        <h3 id="optimizely" style={sectionHeadingStyle}>
          Optimizely
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the analysis tool, Optimizely from the company,
          Optimizely Inc., 681 Howard St. Suite 100 I San Francisco, CA 94105,
          USA (“Optimizely”). When you visit the website, data such as cookies
          and/or device identifiers, IP addresses, interaction times, usage
          times and language settings are collected to enable in particular an
          analysis of responses to new features and changes on the website. The
          data stored and processed in the US is not personal data. If you do
          not agree with the approach of Optimizely, the cookie settings in your
          browser can be adapted accordingly or the functions deactivated via{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.optimizely.com/de/legal/opt-out/.
          </span>
          You can find additional information concerning Optimizely’s data
          protection provisions using the internet address below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://www.optimizely.com/de/privacy/
          </span>
        </p>

        <h3 id="outbrain" style={sectionHeadingStyle}>
          Outbrain Pixel / OutBrain Amplify
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the services, Outbrain Pixel and Outbrain Amplify from
          Outbrain UK Ltd., 5 New Bridge Street, London, EC4V 6JA, UK
          (“Outbrain”). <br />
          <br />
          When you visit our website, an individual ID in the form of cookies is
          stored on your device. This ID allows you to be recognized when using
          the website and collects your usage data. Based on the content
          accessed by you on this and any Outbrain partner websites,
          recommendations can now be made to you for additional possible
          interesting content. Personal data is not stored to do so.
          <br />
          <br />
          If you do not agree with the approach of Outbrain, the cookie settings
          in your browser can be adapted accordingly or the functions
          deactivated via{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.outbrain.com/legal/#advertising_behavioral_targeting.
          </span>
          <br />
          <br />
          You can find additional information concerning Outbrain’s data
          protection provisions using the internet address below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.outbrain.com/legal/privacy
          </span>
        </p>
        <h3 id="polyfill" style={sectionHeadingStyle}>
          Polyfill
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the service, Polyfill from Financial Times Ltd., One
          Southwark Bridge Road, London, UK (“Polyfill”). When you visit our
          website, data is collected concerning your browser (version etc.),
          your internet access (IP address) and the URL which uses the services
          of Polyfill. This data allows Polyfill to detect any discrepancies
          between your current browser version and the technical condition of
          our website. In order to still display a seamless and fully functional
          version of our website via your browser, Polyfill uses so-called
          “polyfills”, code components, which close the technical gaps and make
          the website usable. <br />
          <br />
          No personal data is collected during this process.
          <br />
          <br />
          You can find additional information concerning Polyfill’s data
          protection provisions using the internet address below:
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://polyfill.io/docs/privacy-policy
          </span>
          <br />
          <br />
        </p>
        <h3 id="raygun" style={sectionHeadingStyle}>
          Raygun
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses Raygun, L7, 59 Courtenay Place, Te Aro, Wellington
          6011, New Zealand. <br />
          <br />
          Raygun is integrated with the Luxury Chauffeur Service Ltd websites and monitors their
          performance. This enables the evaluation of crashes and other errors,
          whereby the IP-address and geolocation are collected. Information on
          app crashes is kept for up to six months.
          <br />
          <br />
          For more information, please refer to Raygun’s privacy policy
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            (https://raygun.com/privacy).
          </span>
        </p>
        <h3 id="rudderstack" style={sectionHeadingStyle}>
          Rudderstack
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses RudderStack 96 S. Park Street San Francisco 94107.{" "}
          <br /> <br />
          Among the types of Personal Data that this Website collects, by itself
          or through third parties, there are: Cookies; Usage Data; email
          address; phone number; Universally unique identifier (UUID); Data
          communicated while using the service; first name; last name; company
          name. <br /> <br />
          You can find more information here:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.rudderstack.com/cookie-policy/{" "}
          </span>{" "}
          and{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.rudderstack.com/privacy-policy/?no_markup=false&from_cookie_policy=true{" "}
          </span>{" "}
          And to opt out:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://optout.networkadvertising.org/{" "}
          </span>{" "}
          <br /> <br />
          Any other request to exercise User rights can be directed to the Owner
          by contacting them via email to privacy@rudderstack.com.
        </p>
        <h3 id="salesforce" style={sectionHeadingStyle}>
          Salesforce
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses services from Salesforce.com, Inc., The Landmark at One
          Market, Suite 300, San Francisco, CA 94105, USA (“Salesforce”). <br />
          <br />
          You can find additional information in the Salesforce privacy policy:
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://www.salesforce.com/company/privacy/
            https://www.salesforce.com/company/disclosure/
          </span>
        </p>
        <h3 id="sendgrid" style={sectionHeadingStyle}>
          SendGrid
        </h3>

        <p style={paragraphStyle}>
          For the sending of e-mails, Luxury Chauffeur Service Ltd uses the e-mail provider,
          SendGrid from the company SendGrid Inc., 1801 California Street, Suite
          500, Denver, CO 80202, USA (“SendGrid”).
          <br />
          <br />
          If you provide us with your e-mail address as part of the
          registration, the data required for such purpose (e-mail address,
          language, country) will be transmitted to SendGrid in the USA and
          stored there. In addition to simply sending e-mail, SendGrid provides
          different analysis options to inform us of whether, when and where the
          distributed newsletter are opened, used or rejected. To this end,
          SendGrid uses, amongst other things, cookies and similar technologies.
          <br />
          <br />
          If you do not agree with receiving e-mails not required for the
          service, such as e.g. the newsletter, you can unsubscribe at any time
          using the “unsubscribe” button which is present in every
          communication. You may refuse the use of cookies in your browser
          settings.
          <br />
          <br />
          You can find additional information concerning SendGrid’s data
          protection provisions using the internet address below:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://sendgrid.com/policies/privacy
          </span>
        </p>
        <h3 id="snowplow" style={sectionHeadingStyle}>
          Snowplow
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses Snowplow, a tool of Snowplow Analytics Limited, 32-38
          Scrutton Street, EC2A 4RQ London, United Kingdom ("Snowplow").
          Snowplow will store a cookie on your device with the following
          content: non-refundable random combination number ("hash"), time, URL
          of the page accessed, URL of the previous website (referrer), content
          of the cookies, IDs, anonymized IP address, version and port of the
          browser, operating system. Snowplow is embedded in Luxury Chauffeur Service Ltd's own
          server infrastructure, which means that the data analysed with it does
          not leave the Luxury Chauffeur Service Ltd servers. <br />
          <br />
          For more information, please see Snowplow's privacy policy:
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://snowplowanalytics.com/privacy-policy/
          </span>
        </p>
        <h3 id="twitterAdvertising" style={sectionHeadingStyle}>
          Twitter Advertising
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses the service, Twitter Ads operated by the company,
          Twitter Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, USA
          (“Twitter”). <br /> <br />
          If you visit our website, an ID in the form of a cookie is stored on
          your device. This ID allows you to be recognized when using the
          website and records certain usage data. If you then visit Twitter,
          advertising is displayed to you based on the data transmitted to the
          USA. Twitter does not collect any data which makes you identifiable as
          a person.
          <br /> <br />
          If you do not want Twitter to target you with advertising, you can
          adapt your browser’s cookie settings or deactivate the function at
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads.
          </span>
          <br /> <br />
          You can find additional information concerning Twitter’s data
          protection provisions using the internet address below:
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://twitter.com/de/privacy
          </span>
        </p>
        <h3 id="xandr" style={sectionHeadingStyle}>
          Xandr
        </h3>

        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd uses Xandr, 28 W, 23rd Street, New York, New York, 10010,
          USA. <br />
          <br />
          Xandr is a company that provides technology, data and analytics to
          help companies buy and sell online display advertising.
          <br />
          <br />
          Xandr uses cookies and web beacons to build groups of users (segments)
          that are used to target advertising. Web beacons may also appear on
          these websites and work in conjunction with the cookies to identify
          the types of pages a browser is visiting. It is also possible that web
          beacons may be included in advertising that is using the Xandr service
          to provide data about the success of the adverts and whether browsers
          who clicked on adverts then went on to buy anything.
          <br />
          <br />
          Information that may be tracked about a browser include:
          <br />
          <br />
          the type of browser you use, browser language, browser settings, and
          cookie information. This may include data about you related to bid
          requests an ad impressions, collected when we decide to show you an ad
          on a Digital Property and when we deliver an ad.
          <br />
          <br />
          Information about your device including device operating system
          version, connection type, device make, device model, device
          Identifiers such as your IDFA or AAID, and the IP address from which
          the device accesses a client’s Digital Property.
          <br />
          <br />
          Location information, including precise location information when
          location services have been enabled for an app on your device that has
          integrated our technology or that sends that information to our
          Platform.
          <br />
          <br />
          Information about your activity on a Digital Property such as browsing
          history, viewing history, search history and information regarding an
          individual’s interaction with an internet website, app, or
          advertisement, including the time web pages or apps were visited or
          used, including in-game or online viewing activity (e.g., videos
          viewed, pages viewed).
          <br />
          <br />
          Inferences or audience segmentation derived from Platform Data, such
          as individual profiles, preferences, characteristics, and behaviors.
          <br />
          <br />
          Information about ads served, viewed or clicked on, including the type
          of ad, where the ad was served, whether you clicked on it and whether
          you visited the advertiser’s website or downloaded the advertiser’s
          app.
          <br />
          <br />
          Information about your internet service including information about
          which internet service provider is used by you.
          <br />
          <br />
          You can find more information on Xandr Technology here:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            https://www.xandr.com/privacy/platform-privacy-policy/{" "}
          </span>{" "}
          If you would like to opt out of the Xandr Technology tracking, you can
          find more information on the following page:{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              cursor: "pointer",
            }}
          >
            {" "}
            https://www.xandr.com/privacy/platform-privacy-policy/.
          </span>{" "}
          To review your opt in status or learn more about interest-based
          advertising, please visit the Network Advertising Initiative opt out
          page, the DAA’s WebChoice tool or the DAAC’s WebChoices tool. If you
          have any questions regarding the opt-out, please send an e-mail to
          legal@xandr.com and Xandr will get back to you as soon as possible.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
`;

const Links = styled.a`
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 4px;
  color: black;
  cursor: pointer;
`;
