import React from "react";
import FaqHero from "../component/FaqHero";

function FAQ() {
  return (
    <>
      <FaqHero />
    </>
  );
}

export default FAQ;
