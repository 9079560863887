import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../images/navLogo.png";
import { Image, Offcanvas } from "react-bootstrap";

function Navbar({ fixed }) {
  let location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/");
  };
  const [isMobile, setIsMobile] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width:992px)");
    setIsMobile(mediaQuery.matches);

    const handleMediaQuery = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQuery);
    };
  }, []);

  return (
    <div className={"home-nav-bx"}>
      <Offcanvas
        show={showSideBar}
        style={{ width: "260px", background: "black" }}
        onHide={() => setShowSideBar(false)}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="nav-links responsive-nav-link">
            <li className="nav-link">
              <NavLink to="/about" activeclassname="active-nav">
                About Us
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/green-initiative" activeclassname="active-nav">
                Green Initiative
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/europe-tour" activeclassname="active-nav">
                Europe Tour
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/partners" activeclassname="active-nav">
                Become a Partner
              </NavLink>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Link to="/">
        {/* <img src={logo} alt='luxury-chauffeur' style={{height:isMobile ? "80px" : "120px"}} /> */}
      </Link>
      <nav className={"home-nav nav"}>
        <div className="nav-container">
          <div>
            <Image
              onClick={handleNavigation}
              src={logo}
              alt="logo"
              width={80}
              style={{ cursor: "pointer" }}
            />
          </div>
          {isMobile ? (
            <div className="hrs" onClick={() => setShowSideBar(true)}>
              <hr className="top-hr" />
              <hr className="bottom-hr" />
            </div>
          ) : (
            <div className="nav-contents">
              <ul className="nav-links">
                <li className="nav-link">
                  <NavLink to="/about" activeclassname="active-nav">
                    About Us
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="/green-initiative" activeclassname="active-nav">
                    Green Initiative
                  </NavLink>
                </li>
                {/* <li className="nav-link">
                  <NavLink to="/career" activeclassname="active-nav">
                    Career
                  </NavLink>
                </li> */}
                <li className="nav-link">
                  <NavLink to="/europe-tour" activeclassname="active-nav">
                    Europe Tour
                  </NavLink>
                </li>
                {/* <li className='nav-link'>
              <NavLink to='/faq' activeclassname='active-nav'>
                FAQ
              </NavLink>
            </li> */}
                <li className="nav-link">
                  <NavLink to="/partners" activeclassname="active-nav">
                    Become a partner
                  </NavLink>
                </li>
                {/* <li className='nav-link'>
              <NavLink to='/contact-us' activeclassname='active-nav'>
                Contact Us
              </NavLink>
            </li> */}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
