import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Book, { DateInput, PeopleInput } from "./Book";
import Footer from "./Footer";
import { VscWatch } from "react-icons/vsc";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";
import { MobileTimePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import LocationSearchInput from "./searchLocation";
import { useJsApiLoader } from "@react-google-maps/api";
import styled from "styled-components";

function Basket() {
  const [userDetails, setUserDetails] = useState({
    gender: "",
    pickupTime: "",
    dropTime: "",
    fName: "",
    lName: "",
    email: "",
    number: "",
    flightNo: "",
  });

  const bookingData = JSON.parse(localStorage.getItem("bookingData"));
  const vehicleSelected = JSON.parse(localStorage.getItem("vehicle_selected"));
  const { quoteAmount, vehicle_name, vehicle_img } = vehicleSelected;
  const {
    tripType,
    numHours,
    numPeople,
    from,
    to,
    departureDate,
    returnDate,
    predefined,
  } = bookingData;
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [numPerson, setNumPerson] = useState(1);
  const [startDate, setStartDate] = useState();
  const [loading, setLoading] = useState(false);

  const libraries = ["places"]

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBR-sG3ZqtzdXS0HvgGKu4qwHAEQcy1jO4",
    libraries: libraries,
  });
  if (!isLoaded) {
    return <p>Loading...</p>;
  }

  const handlePayment = async (data) => {
    localStorage.setItem(
      "booking-details",
      JSON.stringify({
        data: data,
        amount: quoteAmount,
        vehicle_name,
        vehicle_img,
        email: userDetails.email,
        name: userDetails.fName + userDetails.lName,
      })
    );

    const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const response = await fetch(
      "https://glittery-cajeta-6bbaa7.netlify.app/.netlify/functions/api/create-checkout-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: data,
          amount: quoteAmount,
          vehicle_name,
          vehicle_img,
          email: userDetails.email,
          name: userDetails.fName + userDetails.lName,
        }),
      }
    );

    const session = await response.json();
    console.log(session, "session");
    const result = stripePromise.redirectToCheckout({ sessionId: session.id });
    console.log(result, "result");
    setLoading(false);
  };

  function handlePay() {
    let dataToSend = {};
    if (predefined) {
      dataToSend.from = origin;
      dataToSend.to = destination;
      dataToSend.numPeople = numPerson;
      dataToSend.departureDate = startDate;
    } else {
      dataToSend = bookingData;
    }

    console.log({ ...dataToSend, ...userDetails }, "dataToSend");

    console.log(userDetails, "userDetails");
    const { gender, pickupTime, dropTime, fName, lName, email } = userDetails;
    if (!gender || !pickupTime || !fName || !lName || !email) {
      toast.error("Fields marked with * are mandatory to fill");
      return;
    }

    if (predefined) {
      if (!startDate || !origin || !destination || !origin) {
        toast.error("Fields marked with * are mandatory to fill");
        return;
      }
    }
    setLoading(true);
    localStorage.setItem("user_details", JSON.stringify(userDetails));
    handlePayment({ ...dataToSend, ...userDetails });
  }
  let isToday =
    new Date(startDate).toDateString() === new Date().toDateString();

  const defaultTime = isToday
    ? dayjs().add(1, "hours")
    : dayjs("2022-04-17T00:00");

  const incrementPeople = () => {
    if (numPerson < 6) {
      setNumPerson((prev) => prev + 1);
    }
  };

  const decrementPeople = () => {
    if (numPerson > 0) {
      setNumPerson((prev) => prev - 1);
    }
  };

  return (
    <Wrapper className="single-transport-section">
      <div className="bcg"></div>
      <div className="single-transport-container">
        <Navbar />
        {!predefined && (
          <Book
            trip_type={tripType}
            d_date={departureDate}
            r_date={returnDate}
            num_hrs={numHours}
            num_people={numPeople}
            from_place={from}
            to_place={to}
          />
        )}

        <div className="user-bx">
          <div className="input-bx">
            <div>
              <p>
                Starting from:{" "}
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>{" "}
              </p>
              <h4>{from}</h4>
              <p>(note: 1 hr margin)</p>
            </div>
            <div className="input-field">
              {/* <input type="time" min="07:00" max="18:00" required onChange={(e) => setUserDetails({...userDetails,pickupTime:e.target.value})} /> */}

              {predefined && (
                <LocationSearchInput
                  label="from (enter postcode)"
                  setOrigin={setOrigin}
                  origin={origin}
                />
              )}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  minTime={defaultTime}
                  defaultValue={defaultTime}
                  onChange={(value) =>
                    setUserDetails({
                      ...userDetails,
                      pickupTime: dayjs(value).format("hh:mm a"),
                    })
                  }
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="input-bx mt-5">
            <div>
              <p>{tripType == "hourly" ? "Hrs. rented for" : "Going to:"}</p>
              <h4>{to}</h4>
            </div>
            {
              <div className="input-field">
                {tripType == "hourly" ? (
                  numHours
                ) : predefined ? (
                  <LocationSearchInput
                    label="to (enter postcode)"
                    setOrigin={setDestination}
                    origin={destination}
                  />
                ) : null}
              </div>
            }
          </div>

          {predefined && (
            <div className="input-bx mt-5">
              <div>
                <p>
                  {"No. of Passengers"}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </p>
              </div>
              <div className="input-field">
                <PeopleInput
                  num={numPerson}
                  increment={incrementPeople}
                  decrement={decrementPeople}
                />
              </div>
            </div>
          )}

          <div className="input-bx mt-5">
            <div>
              <p>{"Flight Number"}</p>
              <p>{"(optional)"}</p>
            </div>
            <div className="input-field">
              <input
                type="text"
                className="input-text"
                placeholder="Flight Number"
                onChange={(e) =>
                  setUserDetails({ ...userDetails, flightNo: e.target.value })
                }
              />
            </div>
          </div>

          {predefined && (
            <div className="input-bx mt-5">
              <div>
                <p>
                  {"Start Date"}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </p>
              </div>
              <div className="input-field">
                <DateInput
                  label="departure"
                  setDate={setStartDate}
                  value={startDate}
                />
              </div>
            </div>
          )}

          <div className="input-bx mt-5">
            <div>
              <h4>
                A Few Details About You
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              </h4>
            </div>
            <div>
              <div className="input-field">
                <div className="d-flex">
                  <input
                    name="gender"
                    type="radio"
                    onClick={(e) =>
                      setUserDetails({ ...userDetails, gender: "Mr." })
                    }
                  />
                  <label className="ms-2">Mr.</label>
                </div>
                <div className="d-flex">
                  <input
                    name="gender"
                    type="radio"
                    onClick={(e) =>
                      setUserDetails({ ...userDetails, gender: "Ms." })
                    }
                  />
                  <label className="ms-2">Ms.</label>
                </div>
              </div>
              <div className="input-field mt-2">
                <input
                  type="text"
                  className="input-text"
                  placeholder="First Name*"
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, fName: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Last Name*"
                  className="input-text"
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, lName: e.target.value })
                  }
                />
              </div>

              <div className="input-field">
                <input
                  type="email"
                  placeholder="Your e-mail*"
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, email: e.target.value })
                  }
                />
              </div>

              <div className="input-field">
                <input
                  type="tel"
                  placeholder="Your number*"
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, number: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="input-bx mt-5">
            <div>
              <p>Current Price:</p>
              <h4>GBP £{quoteAmount.toFixed(2)}</h4>
            </div>

            {
              <button onClick={handlePay} disabled={loading}>
                {loading ? "Loading..." : "Pay"}
              </button>
            }
          </div>
        </div>

        <Footer />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  .book-section {
    position: relative;
  }
  .book-container {
    position: relative;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  .book-container button {
    background-color: transparent;
    padding: 10px 15px;
    border: 0;
    color: #fff;
    border-radius: 10px 10px 0px 0;
    text-transform: capitalize;
  }
  .book-container .btn-active {
    background-color: #fff;
    color: #175af5;
  }
  .book-form > svg {
    color: #ccc;
    font-weight: 800;
    font-size: 1.6rem;
  }
  .book-form {
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: space-around;
    column-gap: 10px;
    padding: 20px 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .book-form .submit-button {
    background-color: blue;
    border-radius: 7px;
    color: #fff;
    width: 10%;
  }

  .book-form .submit-button:hover {
    background-color: black;
    transition: 0.5s ease-in-out;
  }

  .departure-cont {
    display: flex;
    width: 300px;
    /* background-color: red; */
    align-items: center;
    justify-content: center;
  }
  .departure-cont .from-cont {
    width: 50%;
    border-radius: 0;

    input {
      width: 100%;
      border: 0;
    }
  }
  .departure-cont .from-cont:first-child {
    border-radius: 5px 5px 5px 5px;
  }
  .departure-cont .from-cont:nth-child(2) {
    border-radius: 0px 5px 5px 0px;
  }
  .from-cont {
    border: 1px solid #ccc;
    padding: 0 5px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  .from-cont input {
    height: 100%;
    width: 100%;
    margin: 0;
    border: 0;
    background-color: transparent;
    text-transform: capitalize;
    outline: none;
  }
  .from-cont svg {
    font-size: 1.5rem;
    color: #ccc;
    margin-right: 5px;
  }
  .from-cont .count {
    color: #000;
    font-size: 0.8rem;
    margin: 0 5px;
  }
  .people-cont > button {
    background-color: #175af5;
    border-radius: 7px;
    color: #fff;
    width: 100%;
    &:hover {
      background-color: #4e7ff1;
      transition: 0.5s ease-in-out;
    }
  }
  .from-cont .people {
    width: 30px;
    font-size: 1.2rem;
    color: #555;
    margin: 0 5px;
  }
  .people-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }

  .hours-cont > button {
    background-color: #175af5;
    border-radius: 7px;
    color: #fff;
    width: 100%;
    &:hover {
      background-color: #4e7ff1;
      transition: 0.5s ease-in-out;
    }
  }
  .from-cont .hours {
    width: 30px;
    font-size: 1.2rem;
    color: #555;
    margin: 0 5px;
  }
  .hours-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }

  .options {
    position: absolute;
    /* bottom: 0; */
    top: 100px;
    left: 15px;
    width: 50%;

    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    display: none;
  }
  .option {
    padding: 10px 15px;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  .optionTo {
    left: unset;
  }
  .option:hover {
    background-color: #175af5;
    transition: 0.1s ease-in;
  }
  .show-option {
    display: block;
  }
  @media (max-width: 937px) {
    .departure-cont {
      width: 100%;
      margin-top: 20px;
    }

    .people-cont {
      width: 100%;
      column-gap: 10px;
      margin-top: 20px;
    }
    .book-container {
      max-width: 600px;
    }
  }
`;

export default Basket;
