import React from "react";
import image from "../images/partner.jpeg";
import "../styles/PartnerpageHero.css";

function PartnerpageHero() {
  return (
    <section className="partnerpage-hero" style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          inset: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.6)",
        }}
      />
      <img
        className="partner-image1"
        style={{ position: "absolute", width: "100%", height: "100%" }}
        width={"100%"}
        src={image}
        alt=""
      />
      <h1
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
        }}
      >
        Why should you <br /> partner with us?
      </h1>
    </section>
  );
}

export default PartnerpageHero;
