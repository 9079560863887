import React from "react";
import styled from "styled-components";
// import Hero from "./Hero";
import Footer from "./Footer";
import EuropeDestinations from "./EuropeDestinations";
import Navbar from "./Navbar";

function EuropeTour({ main }) {
  return (
    <>
      {/* <Hero form={true} /> */}
      <Navbar />
      <Wrapper>
        <section className='home-destination'>
          <div className='home-destination-container'>
            {main && <h2>Europe Tour</h2>}
            {main && <EuropeDestinations />}

          </div>
        </section>
      </Wrapper>
      <Footer />
    </>
  );
}


const Wrapper = styled.section`
  .home-destination-container {
    margin: 0 auto;
    max-width: var(--max-width);
    background-color: #fff;
    border-radius: 30px;
    padding: 0 40px;
    padding-top: 1px;
    h2,
    h4 {
      text-transform: capitalize;
      text-align: center;
    }
    h2 {
      font-size: 2.5rem;
      color: #1a1919;
      font-weight: 900;
      margin-top:20px
    }
    h4 {
      color: #555;
      margin: 40px 0 30px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      border-radius: 8px;
      border: 0;
      margin: 50px auto;
      background-color: var(--border-color);
      a {
        color: #fff;
        text-transform: capitalize;
      }
    }
  }
  .home-destinations {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    ${'' /* align-items: center; */}
    justify-content: center;
    gap: 30px;
  }
  .home-destination .img-container a {
    color: #fff;
    font-weight: 600;
  }
  .home-destinations div:first-child {
    grid-column: 1/3;
  }
  .home-destinations div:nth-child(2) {
    grid-column: 3/5;
  }
  .home-destinations div:nth-child(3) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .home-destinations div:nth-child(4) {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .home-destinations div:nth-child(5) {
    grid-column: 3/4;
    grid-row: 2/3;
  }
  .home-destinations div:nth-child(6) {
    grid-column: 4/5;
    grid-row: 2/3;
  }

  @media (max-width: 937px) {
  
    .home-destination-container {
      max-width: 600px;
      margin: 0px auto;
      padding: 0

    }
    .home-destinations {
    margin-top: 30px;
    display: block;
 
  }
  }
`;
export default EuropeTour;
