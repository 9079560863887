import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import image from "../images/hero.jpeg";
import Book from "./Book";
import HomeDestinations from "./HomeDestinations";
import { FaSearch } from "react-icons/fa";
import logo from "../images/luxury-chauffeur-logo.png";
import styled from "styled-components";

function Hero({ form }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width:550px)");
    setIsMobile(mediaQuery.matches);

    const handleMediaQuery = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQuery);
    };
  }, []);

  return (
    <>
      <Wrapper className="hero-section">
        <div className="img-container">
          <div className="gradient"></div>
          <img src={image} alt="" />
        </div>
        <div className="hero-container">
          <Navbar />

          <div className="hero-contents">
            <div className="d-flex justify-content-center" >
              <img
                src={logo}
                alt="luxury-chauffeur"
                style={{ height: isMobile ? "80px" : "160px" }}
              />
            </div>
            <h1>Simple Way to Reach Your Vacation Destination</h1>
            <h4>Safe, Reliable and More Affordable Airport Transfers</h4>
            {form || (
              <div className="hero-input">
                <input type="text" placeholder="Find your destination" />
                <FaSearch />
              </div>
            )}
          </div>
        </div>
        {form ? <Book /> : <HomeDestinations main={false} />}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  .hero-contents h1,
  .hero-contents h4 {
    text-align: center;
    max-width: inherit;
  }

  ,
  .hero-contents img {
    position: relative;
    left: "40%";
  }

  ${
    "" /* .hero-section{
  margin-bottom:0
}


.img-container {
  position: relative;
  text-align: center;
  height:auto
} */
  }
`;

export default Hero;
