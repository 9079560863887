import { React, useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled from "styled-components";
import { Link } from "react-router-dom";

function AirportInfo() {
  const [showMoreRoutes, setShowMoreRoutes] = useState(false);
  const moreRoutesSectionRef = useRef(null);

  const additionalRoutes = [
    { destination: "London to Manchester", price: "GBP 430.00" },
    { destination: "London to Cheltenham", price: "GBP 210.00" },
    { destination: "London to Colchester", price: "GBP 125.00" },
    { destination: "London to Coventry", price: "GBP 220.00" },
    { destination: "London to Dover", price: "GBP 150.00" },
    { destination: "London to Ipswich", price: "GBP 130.00" },
    { destination: "London to Leeds", price: "GBP 430.00" },
    { destination: "London to Leicester", price: "GBP 245.00" },
    { destination: "London to Liverpool", price: "GBP 450.00" },
    { destination: "London to Milton Keynes", price: "GBP 110.00" },
    { destination: "London to Northampton", price: "GBP 140.00" },
    { destination: "London to Norwich", price: "GBP 250.00" },
    { destination: "London to Oxford", price: "GBP 110.00" },
    { destination: "London to Portsmouth", price: "GBP 150.00" },
    { destination: "London to Reading", price: "GBP 100.00" },
    { destination: "London to Southampton", price: "GBP 165.00" },
    { destination: "London to York", price: "GBP 450.00" },
  ];

  const routes = [
    { destination: "London to Basingstoke", price: "GBP 95.00" },
    { destination: "London to Birmingham", price: "GBP 255.00" },
    { destination: "London to Bournemouth", price: "GBP 220.00" },
    { destination: "London to Cambridge", price: "GBP 115.00" },
    { destination: "London to Canterbury", price: "GBP 120.00" },
    { destination: "London to Brighton", price: "GBP 150.00" },
    { destination: "London to Bristol", price: "GBP 265.00" },
    { destination: "London to Cardiff", price: "GBP 300.00" },
  ];

  const handleSeeMoreClick = () => {
    setShowMoreRoutes((prev) => !prev);
  };

  const scrollToMoreRoutes = () => {
    moreRoutesSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // After rendering, scroll to the more routes section if "See More" is clicked
    if (showMoreRoutes) {
      scrollToMoreRoutes();
    }
  }, [showMoreRoutes]);

  function Redirect(location) {
    const dataToSave = {
      from: location.split(" ")[0],
      to: location.split(" ")[2],
      miles_covered: "",
      departureDate: new Date(),
      tripType: "",
      returnDate: undefined,
      numHours: "",
      numPeople: 1,
      predefined: true,
    };

    // Save data in local storage
    localStorage.setItem("bookingData", JSON.stringify(dataToSave));
  }

  return (
    <Wrapper>
      <h2 className="popular-routes"> Popular routes </h2>
      <div className={`faq-section`}>
        <div className="faq-categories">
          {[...routes, ...(showMoreRoutes ? additionalRoutes : [])].map(
            (route, index) => (
              <Link
                to={`/transport/${route.destination}`}
                onClick={() => Redirect(route.destination)}
                key={index}
                className="route-link"
              >
                <div className={`route`}>
                  <div className="left-info">
                    <h4>{route.destination}</h4>
                    <p>
                      Price From <span>{route.price}</span>
                    </p>
                  </div>
                </div>
              </Link>
            )
          )}
        </div>
        {showMoreRoutes && (
          <div ref={moreRoutesSectionRef} id="moreRoutesSection">
            {/* Empty div to facilitate smooth scrolling */}
            <div></div>
          </div>
        )}
      </div>
      {additionalRoutes.length > 0 && (
        <div className="see-more-button" onClick={handleSeeMoreClick}>
          <h4>
            {showMoreRoutes ? "See less" : "See more"}{" "}
            {showMoreRoutes ? <FaChevronUp /> : <FaChevronDown />}
          </h4>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  h2 {
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
    margin-bottom: 15px;
  }
  .faq-section {
    transition: max-height 0.5s ease;
  }
  .faq-categories {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    background-color: #fff;

    @media (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 1rem;
    }
  }
  .route-link {
    text-decoration: none;
    color: inherit;
  }
  .route {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #2d303c;
    border-radius: 10px;
    border: 2px solid #e4e1e1cc;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.5s ease;
    transform-origin: center top;
    overflow: hidden;

    &:hover {
      background-color: #f5f5f5;
    }

    &.show-more {
      transform: translateY(100%);
    }
  }
  .left-info h4 {
    margin: 10px 0;
    font-weight: 700;
    font-size: 1.5vh;
  }
  .left-info p {
    margin: 5px 0;
    font-size: 0.95rem;
  }
  .left-info span {
    color: #1919c0;
    font-weight: 600;
  }

  .see-more-button {
    text-align: center;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
  .see-more-button h4 {
    margin: 0;
    font-weight: bold;
  }
  .see-more-button h4 svg {
    font-size: 1.2rem;
    margin-left: 10px;
  }
`;

export default AirportInfo;
