import React from "react";
import styled from "styled-components";
import SingleDestination from "./SingleDestination";
import { Link } from "react-router-dom";
import Booking from "./Booking";
import Destinations from "./Destinations";
import { IoFootsteps } from "react-icons/io5";
import { LuLeafyGreen } from "react-icons/lu";
import { FaHandHoldingHeart } from "react-icons/fa";
import { GiProfit } from "react-icons/gi";

function HomeDestinations({ main }) {
  let careerArr = [
    {
      Icon: LuLeafyGreen,
      title: "Commitment to Sustainability",
      description:
        "We integrate green initiatives into our operations, offering rides in electric vehicles and promoting eco-conscious practices throughout the organization.",
    },
    {
      Icon: IoFootsteps,
      title: "Professional Development",
      description:
        "We value continuous learning and provide opportunities for growth and advancement within the company.",
    },
    {
      Icon: FaHandHoldingHeart,
      title: "Safety First",
      description:
        "Travel with confidence, knowing your safety is our top priority. Our best-in-class service includes rigorous health and cleaning standards.",
    },
  ];

  function CareerCard({ Icon, title, description }) {
    return (
      <div className="career_card">
        <Icon />
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    );
  }

  return (
    <>
      <Wrapper>
        <section className="home-destination">
          <div className="home-destination-container">
            {main && <h2>Airport Transfers</h2>}
            {main && <Destinations />}
          </div>
        </section>
        {main && <Booking main={true} />}
        <div className="career_cards">
          {careerArr.map((k) => (
            <CareerCard {...k} />
          ))}
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 767px) {
    padding: 0 1rem;
  }

  .career_cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin: 4rem auto;

    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  .career_card {
    width: 300px;

    overflow-wrap: break-word;
    text-align: center;
    ${"" /* background: mediumpurple; */}
    padding: 20px;

    svg {
      font-size: 50px;
      color: darkgoldenrod;
    }

    h2 {
      font-size: 24px;
      margin-top: 14px;
    }

    p {
      margin-top: 0 !important;
    }
  }
  .core-content {
    width: 50%;
    margin: 0 auto 40px;
    padding: 0px 100px 0px 0px;

    span.line {
      border-bottom: 4px solid goldenrod;
      width: 10%;
      display: block;
      margin: 15px 0 30px;
    }
    h2 {
      font-size: 2rem;
      font-weight: bold;
    }
    p {
      color: #000000;
      font-family: "Poppins", Sans-serif;
      font-size: 1rem;
      font-weight: 600;
    }

    li {
      color: #000000;
      list-style: disc;
      font-family: "Poppins", Sans-serif;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  .home-destination-container {
    background-color: rgb(255, 255, 255);
    border-radius: 30px 30px 0 0;
    padding: 1px 40px 0px;
    box-shadow: 0px -20px 16px #00000040;
    h2,
    h4 {
      text-transform: capitalize;
      text-align: center;
    }
    h2 {
      font-size: 2.5rem;
      color: #1a1919;
      font-weight: 900;
      padding-top: 25px;
    }
    h4 {
      color: #555;
      margin-top: 0;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      border-radius: 8px;
      border: 0;
      margin: 50px auto;
      background-color: var(--border-color);
      a {
        color: #fff;
        text-transform: capitalize;
      }
    }
  }
  .home-destinations {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  .home-destination .img-container a {
    color: #fff;
    font-weight: 600;
  }
  .home-destinations div:first-child {
    grid-column: 1/3;
  }
  .home-destinations div:nth-child(2) {
    grid-column: 3/5;
  }
  .home-destinations div:nth-child(3) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .home-destinations div:nth-child(4) {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .home-destinations div:nth-child(5) {
    grid-column: 3/4;
    grid-row: 2/3;
  }
  .home-destinations div:nth-child(6) {
    grid-column: 4/5;
    grid-row: 2/3;
  }

  @media (max-width: 937px) {
    .home-destinations {
      margin-top: 30px;
      display: block;
    }
  }

  @media (max-width: 767px) {
    .home-destination-container {
      padding: 1rem !important;
    }
  }
`;
export default HomeDestinations;
