import React from "react";
import { FaChevronRight, FaStar } from "react-icons/fa";
import { Link, useNavigation } from "react-router-dom";
import styled from "styled-components";

function SingleDestination({ text, image, price }) {

  console.log(text,"text");
 

  function Redirect() {
    
    const dataToSave = {
      from: text.split(" ")[0],
      to: text.split(" ")[2],
      miles_covered: "",
      departureDate: new Date(),
      tripType:"",
      returnDate: undefined,
      numHours:  "",
      numPeople:1,
      predefined:true
    };

    // Save data in local storage
    localStorage.setItem("bookingData", JSON.stringify(dataToSave));
  }


  return (
    <Wrapper onClick={Redirect} >
    <Link to={`/transport/${text}`}>
    <div className='img-container'>
        <div className='img-gradient'>
          <p >View destination</p>
          <FaChevronRight />
        </div>
        <img src={image} alt='' />
      </div>
    </Link>
      
      <h3>{text}</h3>
      {/* <p>
        5.0 <FaStar></FaStar>
        <FaStar></FaStar>
        <FaStar></FaStar>
        <FaStar></FaStar>
        <FaStar></FaStar>
        <span>657 reviews</span>
      </p> */}
      <p>Transfer Prices from £{price}</p>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .img-container img {
    width: 100%;
    border-radius: 20px;
    height: 100%;
  }
  .img-container {
    height: 300px;
    position: relative;
  }
  h3 {
    text-transform: capitalize;
    margin-bottom: 0;
    font-size:20px;
margin-top:10px;
  }
  p:nth-child(3) {
    color: var(--border-color);
  }
  p {
    margin: 10px 0;
  }
  svg {
    color: var(--border-color);
  }
  p span {
    color: #b1aeae;
    margin-left: 10px;
  }
  .img-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    color: #fff;

    opacity: 0;
    h5,
    a {
      font-weight: 100;
      font-size: 1.1rem;
    }
    svg {
      color: #fff;
      margin: 0;
    }
  }
  @media (hover: hover) {
    .img-container:hover {
      .img-gradient {
        opacity: 1;
        transition: 0.5s ease-in-out;
      }
    }
  }
`;
export default SingleDestination;
