import { useState } from "react";
import { FaChevronLeft, FaChevronRight, FaSuitcase, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const VehicleCard = ({detail,maxPassengers, maxBaggage, imgs,quote,vehicle_type,models }) => {

  const [initialIndex, setInitialIndex] = useState(0)
  const navigate = useNavigate()

  console.log(initialIndex,"initialIndex");

  const handlePrev = (setIndex, length) => {
    setIndex(prevIndex => (prevIndex === 0 ? length - 1 : prevIndex - 1));
  };

  const handleNext = (setIndex, length) => {
    setIndex(prevIndex => (prevIndex === length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className={`${vehicle_type}-vehicles`}>
              <h2>{vehicle_type} Vehicles</h2>
                <div className='vehicle-cards'>
                  <button className="sliders-btn sliders-btn-left" onClick={() => handlePrev(setInitialIndex,models.length)}><FaChevronLeft /></button>
    <div className="vehicle-content">
      <img
        src={imgs[initialIndex]}
        alt=""
      />
      <div className="info">
        <h5>{models[initialIndex]}</h5>
        <ul>
          <li>
            <FaUser /> Place for {maxPassengers} people
          </li>
          <li>
            <FaSuitcase /> Fits {maxBaggage} medium suitcase
          </li>
        </ul>
      </div>
      <div className="info">
        <small>Price from: £{quote?.toFixed(2)}</small>
        <h4>Choose date</h4>
        <button className="btn date-btn" disabled={quote < 45} onClick={() => {
          let data = {...detail,vehicle_name:vehicle_type?.toUpperCase(),vehicle_img:imgs[0]}
          localStorage.setItem("vehicle_selected",JSON.stringify(data))
          navigate("/basket")}} >
          Choose this vehicle <FaChevronRight />
        </button>
      </div>
    </div>
    <button className="sliders-btn sliders-btn-right" onClick={() => handleNext(setInitialIndex,models.length)}><FaChevronRight /></button>
                </div>
            </div>
  );
};
export default VehicleCard;
