import React from "react";
import SingleDestination from "./SingleDestination";
import LHR from "../images/LHR.jpeg";
import LGW from "../images/LGW.jpg";
import LCY from "../images/LCY.jpg";
import LTN from "../images/LTN.jpg";
import STN from "../images/STN.jpg";
import SEN from "../images/SEN.jpg";


function Destinations() {
  return (
    <div className='home-destinations'>
      <SingleDestination text="LHR to Central London" image={LHR} price={65} />
      <SingleDestination text="LGW to Central London" image={LGW} price={95} />
      <SingleDestination text="LCY to Central London" image={LCY} price={45} />
      <SingleDestination text="LTN to Central London" image={LTN} price={90} />
      <SingleDestination text="STN to Central London" image={STN} price={90} />
      <SingleDestination text="SEN to Central London" image={SEN} price={90} />
    </div>
  );
}

export default Destinations;
