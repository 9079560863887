import React from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import styled from "styled-components";

function Terms() {
  const containerStyle = {
    maxWidth: "800px",
    margin: "auto",
    padding: "20px",
    fontFamily: "AvenirNextDemiBold, Helvetica, Arial, Open Sans, sans-serif",
    lineHeight: "1.6",
  };

  const headingStyle = {
    color: "#333",
    fontSize: "28px",
    marginBottom: "10px",
    fontWeight: "600",
  };

  const sectionHeadingStyle = {
    color: "#666",
    fontSize: "20px",
    marginTop: "20px",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    marginBottom: "15px",
  };

  return (
    <div>
      <Navbar />
      <div style={containerStyle}>
        <h2 style={headingStyle}>
          Luxury Chauffeur Service Ltd Terms and Conditions
        </h2>

        <LinksWrapper>
          <Links href="#general">1. GENERAL PROVISIONS</Links>
          <Links href="#contractual">
            2. Contractual Relationship and Contract Conclusion
          </Links>
          <Links href="#registration">
            3. Registration Obligation of the User for the Use of the BL Tools
          </Links>
          <Links href="#transport">
            4. Selected Content of the Transportation Contract for the Benefit
            of the User
          </Links>
          <Links href="#remuneration">5. Remuneration and Payment</Links>
          <Links href="#liability">6. Liability</Links>
          <Links href="#amendment">
            7. Amendment of the Offer by Luxury Chauffeur Service Ltd{" "}
          </Links>
          <Links href="#protection">
            8. Protection of Content, Grant of Rights of Use to BL Tools
          </Links>
          <Links href="#final">9.Final Provisions</Links>
        </LinksWrapper>

        <h3 style={sectionHeadingStyle} id="general">
          General Provisions
        </h3>
        <h4 style={sectionHeadingStyle}>1. GENERAL PROVISIONS</h4>
        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd, REPLACE WITH ADRESS enables its users to
          book ride services via its own online platform by integrating
          third-party online platforms and applications for mobile devices.
          Luxury Chauffeur Service Ltd's service only consists of providing a
          client for transportation against an independent ride service provider
          ([Transportation Service Provider] "TSP") as a way of arranging for a
          business service for the user, not in rendering ride services.
        </p>
        <p style={paragraphStyle}>
          These General Terms and Conditions (hereinafter referred to as “T&Cs”)
          are part of each agreement of the user concerning Luxury Chauffeur
          Service Ltd's arrangement of business service contracts. They also
          describe details of the ride services for which Luxury Chauffeur
          Service Ltd provides the user a direct claim against an TSP.
        </p>
        <p style={paragraphStyle}>
          Conflicting general terms and conditions of the user are hereby also
          contradicted in the case of confirmation letters and services accepted
          without condition. Any terms to the contrary shall only apply insofar
          as the management of Luxury Chauffeur Service Ltd has expressly
          consented to this in writing.
        </p>

        <h3 style={sectionHeadingStyle} id="contractual">
          Contractual Relationship
        </h3>
        <h4 style={sectionHeadingStyle}>2.1 CONTRACTUAL RELATIONSHIP</h4>
        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd does not provide the ride services
          presented within the scope of the Luxury Chauffeur Service Ltd Tools
          either itself or by way of third parties. Luxury Chauffeur Service Ltd
          only provides the user with a claim for transport against an TSP,
          which is independent of Luxury Chauffeur Service Ltd.
        </p>
        <p style={paragraphStyle}>
          For this purpose, Luxury Chauffeur Service Ltd concludes on its own
          behalf the necessary agreements with the TSP that provide the user
          with a claim for transport against the TSP ("contract for the benefit
          of third parties", also "transportation contract for the benefit of
          the user"). From this, the user is entitled to request the ride
          service and further claims directly from the TSP.
        </p>
        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd and the user only agree to the
          arrangement of a business service contract and no transportation
          services. The claim for remuneration by Luxury Chauffeur Service Ltd
          includes the remuneration for arranging business services as well as
          the transportation remuneration disbursed to the TSP.
        </p>

        <h4 style={sectionHeadingStyle}>2.2 CONTRACTUAL RELATIONSHIP</h4>
        <p style={paragraphStyle}>
          By transmitting a completed booking form over Luxury Chauffeur Service
          Ltd Tools or making arrangements by telephone with Luxury Chauffeur
          Service Ltd, the user transmits an offer to conclude a business
          service contract (“ride request” of the user). The subject matter of
          this contract is the provision of the ride service requested by the
          user.
        </p>
        <p style={paragraphStyle}>
          As an initial matter, Luxury Chauffeur Service Ltd transmits to the
          user an email confirmation of receipt with the details of the ride
          service to be provided. In so doing, Luxury Chauffeur Service Ltd only
          confirms the receipt of the user's ride request.
        </p>
        <p style={paragraphStyle}>
          It is only by separate declaration (“booking confirmation”) by email
          from Luxury Chauffeur Service Ltd that the business service contract
          between Luxury Chauffeur Service Ltd and the user is concluded for the
          desired ride service. The user is then directly entitled vis-à-vis the
          TSP to request the ride service of the TSP and to assert further
          claims directly from the TSP.
        </p>

        <h3 style={sectionHeadingStyle} id="registration">
          3. REGISTRATION OBLIGATION OF THE USER FOR THE USE OF THE Luxury
          Chauffeur Service Ltd Tools
        </h3>
        <p style={paragraphStyle}>
          The user provides assurance to Luxury Chauffeur Service Ltd that all
          information they transmit or have transmitted to Luxury Chauffeur
          Service Ltd on their behalf by another person is complete and
          accurate. Registrations by automated processes are prohibited.
        </p>

        <h3 style={sectionHeadingStyle} id="transport">
          4. SELECTED CONTENT OF THE TRANSPORTATION CONTRACT FOR THE BENEFIT OF
          THE USER
        </h3>
        <p style={paragraphStyle}>
          The user can only request the details for a user's ride request
          (together with “ride modalities”) described under Section 4 from the
          TSP if this has been agreed with Luxury Chauffeur Service Ltd in the
          business service contract.
        </p>
        <p style={paragraphStyle}>
          For the transport claim of the user to be asserted directly towards
          the TSP and to be procured by Luxury Chauffeur Service Ltd, the
          following conditions apply:
        </p>

        <h4 style={sectionHeadingStyle}>4.1 RIDE TYPES, SERVICE CHANGES</h4>
        <p style={paragraphStyle}>
          Depending on local availability, the User can select for the Ride
          Request between transfer rides, long-distance rides (transfer rides
          starting at 200 km), rides on demand (“chauffeur hailing”) and hourly
          bookings.
        </p>
        <p style={paragraphStyle}>
          If the ride actually carried out involves additional expenditure of
          effort due to the user's or guest's preference as compared to the ride
          requested, the TSP will allow for this additional effort to the extent
          possible. The additional effort may result in additional costs for the
          individual business service contract; see Section 5 below for details.
        </p>
        <p style={paragraphStyle}>
          Subject to availability, changes to ride modalities are possible even
          after the conclusion of the contract by the user or the guest pursuant
          to Section 5.2 with the remuneration consequences described therein..
        </p>

        <h4 style={sectionHeadingStyle}>4.1.1</h4>
        <p style={paragraphStyle}>
          In the case of transfer, long-distance and rides on demand, the
          indicated price shall apply to a start and a destination address. An
          additional fee may be incurred per stopover on a direct route
          according to the price structure applicable (see Section 5 below).
        </p>

        <h4 style={sectionHeadingStyle}>4.1.2</h4>
        <p style={paragraphStyle}>
          An hourly booking always begins at the booked pickup time and ends in
          the city area of the pickup location. If the TSP allows the completion
          of the ride outside the city area of the pickup location at the User's
          request or if the included free kilometers or the booking duration are
          exceeded, this additional effort results in additional costs,
          particularly due to the fact that the TSP must return to the city area
          of the pickup location.
        </p>

        <h4 style={sectionHeadingStyle}>4.2 PICKUP TIME</h4>
        <p style={paragraphStyle}>
          The agreed pickup time is the pickup time specified in the Luxury
          Chauffeur Service Ltd booking confirmation.
        </p>
        <p style={paragraphStyle}>
          In the event of airport pickup or pickup at a long-distance train
          station where the User has provided a correct flight or train number
          during the booking and thus enabled the tracking of the arrival time
          of the flight or train, the agreed pickup time will be shifted
          accordingly in case of deviations in the arrival time.
        </p>

        <h4 style={sectionHeadingStyle}>
          4.3 VEHICLE CLASS/VEHICLE MODEL, UPGRADE
        </h4>
        <p style={paragraphStyle}>
          Depending on the regional availability of the vehicle, the user can
          choose from different vehicle classes for their ride request (for
          example, “Business Class”, ”Business Van/SUV”, ”First Class”,
          “Sprinter Class” or “Electric Class”).
        </p>
        <p style={paragraphStyle}>
          The vehicles shown within the framework of the Luxury Chauffeur
          Service Ltd Tools are only illustrative examples. There is no right to
          a particular vehicle model associated with a booked vehicle class; in
          particular, regional differences are possible.
        </p>
        <p style={paragraphStyle}>
          It is possible for Luxury Chauffeur Service Ltd to upgrade from the
          vehicle class “Business Class” to a higher vehicle class (such as
          “Business Van” or “First Class”) at any time at no additional cost for
          the user depending on availability.
        </p>

        <h3 style={sectionHeadingStyle}>4.4 TRANSPORT SAFETY, CONSEQUENCES</h3>

        <h4 style={sectionHeadingStyle}>4.4.1 LUGGAGE, ANIMALS</h4>
        <p style={paragraphStyle}>
          The price in the booking confirmation includes the number of luggage
          items specified in the booking form.
        </p>
        <p style={paragraphStyle}>
          Excess luggage, bulky luggage such as a wheelchair, weapons or animals
          that the User wishes to carry along must be specified during the
          booking. The TSP may refuse the transport of luggage, weapons and/or
          animals that have not been agreed upon; this also applies if animals
          are not housed in a closed and suitable transport box. The right of
          refusal does not exist if local statutory provisions of the region in
          which the transport is carried out require that the items be
          accommodated.
        </p>
        <p style={paragraphStyle}>
          If the TSP enables the transport of non-stipulated luggage, weapons
          and/or animals, corresponding surcharges may be charged; accordingly,
          the remuneration for the business service contract then will turn out
          to be higher than specified in the booking confirmation (see Section 5
          below).
        </p>

        <h4 style={sectionHeadingStyle}>
          4.4.2 TRANSPORT OF CHILDREN AND MINORS
        </h4>
        <p style={paragraphStyle}>
          (A) CHILDREN The need for child restraints for children must be
          specified by the user in the ride request by specifying the number and
          age of the children to be transported as well as the type of child
          restraints required.
        </p>
        <p style={paragraphStyle}>
          (B) MINORS The transport of unaccompanied minors can be rejected by
          the TSP. The legal age of majority or minor status is determined by
          the statutory provisions of the region in which the transport is to be
          performed.
        </p>

        <h4 style={sectionHeadingStyle}>
          4.4.3 INFORMATION ON NUMBER OF PASSENGERS, NUMBER AND SIZE OF LUGGAGE
        </h4>
        <p style={paragraphStyle}>
          The maximum number of passengers, number and size of luggage provided
          by Luxury Chauffeur Service Ltd for a specific vehicle is set out in
          the binding luggage policy.
        </p>
        <p style={paragraphStyle}>
          The TSP may refuse to transport passengers or luggage if, in its
          opinion, the space and safety conditions do not permit it.
        </p>

        <h4 style={sectionHeadingStyle}>4.4.4 IMPEDED TRANSPORT</h4>
        <p style={paragraphStyle}>
          The TSP may refuse a transport if mandatory (for example, resulting
          from applicable law) requirements pursuant to this Section 4.3 have
          not been communicated or were not correctly communicated by the user
          in the ride request.
        </p>
        <p style={paragraphStyle}>
          If transport is not possible for this reason, this does not have any
          impact on the remuneration of Luxury Chauffeur Service Ltd under the
          business service contract with the user for the specific
          transportation.
        </p>

        <h4 style={sectionHeadingStyle}>4.5 DELAYS</h4>
        <p style={paragraphStyle}>
          Exceptional situations such as striking air traffic controllers,
          terrible weather conditions, etc. can only be compensated to a limited
          extent, so in these cases, longer waiting times or short-notice
          cancellations must be accepted by the user.
        </p>

        <h3 style={sectionHeadingStyle}>
          4.6 CANCELLATIONS, REBOOKINGS AND NO-SHOW RIDES
        </h3>

        <h4 style={sectionHeadingStyle}>4.6.1 CANCELLATION</h4>
        <p style={paragraphStyle}>
          For transfer rides, long-distance rides (transfer rides starting at
          200 km) and hourly bookings, the cancellation is free if the time
          between the cancellation and the agreed pickup time is greater than
          one hour. If the time between the cancellation and the agreed pickup
          time is one hour or less, the full price must be paid. An effective
          cancellation can only be carried out via the cancellation function on
          the website or in the app.
        </p>

        <h4 style={sectionHeadingStyle}>4.6.2 REBOOKINGS</h4>
        <p style={paragraphStyle}>
          Rebookings are generally treated as new bookings. The regulations for
          the handling of cancellations (Section 4.5.1 above) apply accordingly
          for the originally agreed ride. Accordingly, a remuneration claim by
          Luxury Chauffeur Service Ltd for the originally agreed ride may remain
          in force.
        </p>

        <h4 style={sectionHeadingStyle}>
          4.6.3 NO-SHOW RIDES WITHOUT CANCELLATION, USER'S DELAY
        </h4>
        <p style={paragraphStyle}>
          In the event of a no-show ride without a cancellation, the User's
          claim for transport vis-à-vis the TSP no longer applies; however,
          Luxury Chauffeur Service Ltd's claim for remuneration against the User
          still applies.
        </p>
        <p style={paragraphStyle}>
          (A) FOR TRANSFER AND LONG-DISTANCE RIDES A ride is considered to be a
          no-show if the User or guest does not appear within 30 minutes after
          the agreed pickup time at the agreed pickup location.
        </p>
        <p style={paragraphStyle}>
          In the event of pickup at airports or long-distance train stations, a
          ride is considered to be a no-show if the User or guest does not
          appear within 60 minutes after the agreed pickup time at the agreed
          pickup location.
        </p>
        <p style={paragraphStyle}>
          No-show rides must be fully remunerated, but any waiting time
          surcharges are not applied. This is not the case if the TSP and the
          guest have communicated by telephone about a later pickup time. Any
          waiting time surcharges must be remunerated as described under Section
          5.3.1. As a general rule, there is no right to change the pickup time.
        </p>
        <p style={paragraphStyle}>
          (B) HOURLY BOOKING A ride is considered to be a no-show if the User or
          guest does not appear at the agreed pickup location after the end of
          the booked hours according to the pickup time.
        </p>
        <p style={paragraphStyle}>
          In the event of pickup at airports or long-distance train stations, a
          ride is considered to be a no-show if the User or guest does not
          appear at the agreed location after the end of the booked hours
          according to the pickup time.
        </p>
        <p style={paragraphStyle}>
          No-show rides must be fully remunerated. This is not the case if the
          TSP and the guest have communicated by telephone about a later pickup
          time. The hourly booking always starts as described under Section
          4.1.2 at the agreed pickup time. In this respect, any extensions of
          the hourly booking must be remunerated as described under Section 5.2.
          As a general rule, there is no right to change the pickup time.
        </p>

        <h4 style={sectionHeadingStyle}>4.7 BEHAVIOR IN THE LIMOUSINE</h4>
        <p style={paragraphStyle}>
          The following behavioral standards apply to the users of the TSP's
          transport services:
        </p>
        <p style={paragraphStyle}>
          The following behavioral standards apply to the users of the TSP's
          transport services:
        </p>
        <p style={paragraphStyle}>
          Throughout the entire ride time, the respective applicable regulations
          for the regulation of road traffic apply for all guests, particularly
          the obligation to wear a seat belt. The TSP's instructions must always
          be followed. The TSP bears the responsibility for safely completing
          the ride. Therefore, the guests are prohibited from opening the doors
          during the ride, throwing objects out of the vehicle and/or physically
          hanging out any part of the body or screaming from the vehicle. If
          guests wish to use any equipment or systems present in the respective
          vehicle, prior instruction by the TSP is required.
        </p>
        <p style={paragraphStyle}>
          Smoking is prohibited in the passenger compartment of the vehicles. If
          the user or a guest ignores this, the user must bear the costs of a
          vehicle cleaning and the resulting loss of serviceability.
        </p>
        <p style={paragraphStyle}>
          The consumption of food is discouraged. Alcoholic drinks are only
          allowed to be consumed in the car with prior consent.
        </p>

        <h4 style={sectionHeadingStyle} id="remuneration">
          5.1 RIDE CHANGES
        </h4>
        <p style={paragraphStyle}>
          The user (and also the guest) may also change the ride modalities
          after the conclusion of the business service contract and, to the
          extent possible for the TSP, after the ride is started.
        </p>
        <p style={paragraphStyle}>
          In the event of a spontaneous upgrade of the ride by request of the
          user or guest (distance or number of hours), the actual service (total
          distance or number of hours) is recalculated and charged according to
          the respective valid price structure. In the case of hourly bookings,
          any half hour commenced is determinative for the invoicing; in other
          words, the half hour is rounded up from the first additional minute in
          the interest of better planning reliability.
        </p>
        <p style={paragraphStyle}>
          Accordingly, the remuneration claim vis-à-vis the user increases, as
          there is a corresponding increase in the reimbursement of Luxury
          Chauffeur Service Ltd's expenses for the business service contract on
          behalf of the user.
        </p>
        <p style={paragraphStyle}>
          If the booked distance or number of hours is shortened as compared to
          the booking, the agreed remuneration remains unaffected.
        </p>

        <h4 style={sectionHeadingStyle}>5.2 OTHER CHARGES</h4>

        <h4 style={sectionHeadingStyle}>
          5.2.1 WAITING TIMES FOR TRANSFER RIDES
        </h4>
        <p style={paragraphStyle}>
          In the case of transfer rides, no surcharges will apply for waiting
          times with pickup at airports or long-distance train stations up to 60
          minutes after the agreed pickup time. In all other cases, no
          surcharges will apply for waiting times up to 15 minutes from the
          agreed pickup time. Each additional minute of waiting time is invoiced
          as a share of the hourly booking prices applicable in the respective
          city area and for the respective vehicle class, plus the applicable
          sales tax.
        </p>

        <h4 style={sectionHeadingStyle}>
          5.2.2 ADDITIONAL KILOMETERS FOR HOURLY BOOKINGS{" "}
        </h4>
        <p style={paragraphStyle}>
          Hourly bookings consist of the inclusive kilometers (per hour)
          communicated in the booking form (or by telephone). Additional
          kilometers are separately remunerated and are based on the route
          prices for the booked vehicle class in the respective city area and
          are invoiced in addition to the applicable sales tax.
        </p>

        <h4 style={sectionHeadingStyle}>
          5.3 PAYMENT MODALITIES AND TRANSACTION FEES{" "}
        </h4>
        <p style={paragraphStyle}>
          The user can pay for their ride by credit card. Any credit card fees
          accrued are borne by Luxury Chauffeur Service Ltd. The user bears any
          transaction fees in the event of a payment by transfer (for example,
          due to different currencies or locally different accounts).
        </p>

        <h4 style={sectionHeadingStyle}>
          5.4 WARNING NOTICES, FAILED CREDIT CARD DIRECT DEBITING{" "}
        </h4>
        <p style={paragraphStyle}>
          For each payment reminder notice, Luxury Chauffeur Service Ltd may
          charge a reasonable reminder fee.
        </p>
        <p style={paragraphStyle}>
          For non-redeemable credit card debit authorizations, Luxury Chauffeur
          Service Ltd invoices the user for the expenses incurred for this
          (bank, credit card institution) and reserves the right to assert a
          reasonable processing fee per incident.
        </p>

        <h4 style={sectionHeadingStyle}>
          5.5 TRANSMISSION OF INVOICES, DUE DATE{" "}
        </h4>
        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd electronically makes the respective
          invoice available to the user for download in the User account. If
          payment is made by credit card, the remuneration is due for payment
          immediately. If payment is made by bank transfer, the payment term
          referred to in the invoice applies.
        </p>

        <h3 style={sectionHeadingStyle} id="liability">
          6. LIABILITY{" "}
        </h3>
        <h4 style={sectionHeadingStyle}>6.1 GENERAL PRINCIPLES </h4>
        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd is liable for damages caused by Luxury
          Chauffeur Service Ltd or its vicarious and service agents through
          intent or due to gross negligence. The TSP, including all the
          chauffeurs employed by the latter, are neither vicarious nor service
          agents of Luxury Chauffeur Service Ltd for the performance of
          transportation services. Rather, Luxury Chauffeur Service Ltd provides
          the User with a direct claim for transport services vis-à-vis the TSP.
        </p>
        <p style={paragraphStyle}>
          In the event of damages caused by simple negligence, Luxury Chauffeur
          Service Ltd is liable only for the violation of a material contractual
          obligation and only for foreseeable and typical damages. Material
          contractual obligations are those that enable the fulfillment of the
          contract in the first place and upon the fulfillment of which the user
          may ordinarily rely.
        </p>
        <p style={paragraphStyle}>
          Limitations of liability are not applicable within the scope of
          warranties provided, in the event of injuries to life, the body and
          health, as well as for claims arising from the Product Liability Act.
        </p>

        <h4 style={sectionHeadingStyle}>6.2 THIRD-PARTY WEBSITES </h4>
        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd accepts no responsibility for the
          content, freedom from error, legality and functionality of third-party
          websites referred to by means of links. Links are accessed at your own
          risk.
        </p>

        <h4 style={sectionHeadingStyle}>
          6.3 CORRECTNESS OF TRANSMITTED INFORMATION, DISRUPTION OF ACCESS{" "}
        </h4>
        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd accepts no responsibility for the correct
          and complete transmission of information and that it reaches users or
          chauffeurs in a timely manner. The content of the booking confirmation
          is excepted from this.
        </p>
        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd is not liable for disruptions in the
          quality of access to the Luxury Chauffeur Service Ltd Tools due to
          force majeure or due to events for which Luxury Chauffeur Service Ltd
          is not responsible, particularly the failure of communication networks
          and/or gateways. Luxury Chauffeur Service Ltd does not provide any
          guarantee that the website will function free of interruption and
          error or that any errors will be corrected.
        </p>

        <h4 style={sectionHeadingStyle}>6.4 INDEMNITY FROM THE USER </h4>
        <p style={paragraphStyle}>
          The user shall indemnify Luxury Chauffeur Service Ltd for all claims
          and costs, including reasonable costs for a legal defense, which a
          third party asserts against Luxury Chauffeur Service Ltd owing to a
          non-contractual use of the Luxury Chauffeur Service Ltd Tools or
          because of a violation of these General Terms & Conditions.
        </p>

        <h4 style={sectionHeadingStyle}>6.5 OBJECTS LEFT BEHIND </h4>
        <p style={paragraphStyle}>
          No liability is assumed for objects forgotten in the vehicle.
        </p>

        <h4 style={sectionHeadingStyle} id="amendment">
          7. AMENDMENT OF THE OFFER OF Luxury Chauffeur Service Ltd{" "}
        </h4>
        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd reserves the right to change the Luxury
          Chauffeur Service Ltd Tools at any time in a manner reasonable for the
          user in order to further develop and improve them qualitatively.
          Moreover, Luxury Chauffeur Service Ltd has the right to temporarily or
          permanently terminate its offer via Luxury Chauffeur Service Ltd Tools
          for a material reason, even without separately informing the user.
        </p>

        <h4 style={sectionHeadingStyle} id="protection">
          8. PROTECTION OF CONTENT, GRANT OF RIGHTS OF USE TO Luxury Chauffeur
          Service Ltd Tools{" "}
        </h4>
        <p style={paragraphStyle}>
          The content contained within the framework of Luxury Chauffeur Service
          Ltd Tools is protected by copyright law
        </p>
        <p style={paragraphStyle}>
          Luxury Chauffeur Service Ltd grants the user the revocable right to
          use the Luxury Chauffeur Service Ltd Tools according to its intended
          purpose conditioned on the case of compliance with these T&Cs. Any use
          beyond this scope (such as changes, copies, re-releases, transmission,
          dissemination or other non-intended purposes) is prohibited.
        </p>

        {/* Add other sections similarly */}

        <h3 style={sectionHeadingStyle} id="final">
          Final Provisions
        </h3>
        <h4 style={sectionHeadingStyle}>9. FINAL PROVISIONS</h4>
        <p style={paragraphStyle}>
          These General Terms & Conditions constitute the entire agreement
          between Luxury Chauffeur Service Ltd and the user for the
          contractually agreed services. There are no collateral agreements.
          Amendments and additions to this Agreement must be made in writing to
          be valid.
        </p>
        <p style={paragraphStyle}>
          The user may only set off against Luxury Chauffeur Service Ltd's
          claims and assert retention rights if their counterclaims are legally
          established or undisputed. This also applies to the user's notice of
          defects.
        </p>
        <p style={paragraphStyle}>
          The user may only assert retention rights from claims under this
          Section 9.3(1) to the extent that they are based on the same
          contractual relationship.
        </p>
        <p style={paragraphStyle}>
          The user is not entitled to assign claims from the contractual
          relationship to third parties without the express written consent of
          Luxury Chauffeur Service Ltd.
        </p>
        <p style={paragraphStyle}>
          The law of the United Kingdom applies to all legal relations between
          Luxury Chauffeur Service Ltd and the user residing in the UK.
        </p>
        <p style={paragraphStyle}>Place of fulfillment is United Kingdom.</p>
        {/* Add other subsections under "Final Provisions" similarly */}
      </div>
      <Footer />
    </div>
  );
}

export default Terms;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
`;

const Links = styled.a`
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 4px;
  color: black;
  cursor: pointer;
`;
