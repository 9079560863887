import React, {useEffect, useState} from "react";
import { FaChevronLeft, FaChevronRight, FaSuitcase, FaUser } from "react-icons/fa";
import Book from "../component/Book";
import Footer from "../component/Footer";
import Navbar from "../component/Navbar";
import VehicleCard from "../component/VehicleCard";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";


// const vehicleTypes = {
//   'standard': {
//       'rate': 10.0,
//       'models': ['Hyundai Ioniq', 'Toyota Prius', 'Kia Niro'],
//       'imgs' : ['https://i.imgur.com/yJZefBJ.png', 'https://imgur.com/OJ6fg6l.png', 'https://i.imgur.com/HenVVDT.png'] ,
//       'maxPassengers': 4,
//       'maxBaggage': 2,
//   },
//   'executive': {
//       'rate': 15.0,
//       'models': ['Mercedes-Benz E-class', 'Mercedes-Benz EQE', 'BMW 5 series', 'Jaguar I-Pace'],
//       'imgs' : ['https://imgur.com/oPhL35l.png', 'https://imgur.com/BfLNfq3.png', 'https://i.imgur.com/8JjxXUm.png', 'https://imgur.com/Omwf8gt.png'] ,
//       'maxPassengers': 4,
//       'maxBaggage': 2,
//   },
//   'luxe': {
//       'rate': 20.0,
//       'models': ['Mercedes-Benz S-Class', 'Range Rover'],
//       'imgs' : ['https://imgur.com/TSKXXSC.png', 'https://imgur.com/Qg1ZlGI.png'] ,
//       'maxPassengers': 4,
//       'maxBaggage': 2,
//   },
//   'electric': {
//       'rate': 25.0,
//       'models': ['Mercedes-Benz EQE', 'Jaguar I-Pace'],
//       'imgs' : ['https://imgur.com/BfLNfq3.png', 'https://imgur.com/Omwf8gt.png'] ,
//       'maxPassengers': 4,
//       'maxBaggage': 2,
//   },
//   '7-seater': {
//       'rate': 30.0,
//       'models': ['Mercedes-Benz V-Class', 'Mercedes-Benz Vito'],
//       'imgs' : ['https://imgur.com/mFMb0qK.png', 'https://imgur.com/mFMb0qK.png'] ,
//       'maxPassengers': 6,
//       'maxBaggage': 5,
//   },
// };

function SingleTransport() {

  const [standardIndex, setStandardIndex] = useState(0);
  const [luxeIndex, setLuxeIndex] = useState(0);
  const [executiveIndex, setExecutiveIndex] = useState(0);
  const [electricIndex, setElectricIndex] = useState(0);
  const [seaterIndex, setSeaterIndex] = useState(0);
const [vehicleTypes, setVehicleTypes] = useState([])
const [loading, setLoading] = useState(false)
const bookingData = JSON.parse(localStorage.getItem("bookingData"))
const {miles_covered ,tripType,numHours,numPeople,from,to,departureDate,returnDate,predefined} = bookingData


  const {route} = useParams()
  console.log(vehicleTypes,"vehicleTypes");

  console.log(route,"route");

  useEffect(() => {
    setLoading(true)
    axios.post("https://glittery-cajeta-6bbaa7.netlify.app/.netlify/functions/api/quote",{
      "miles_covered":miles_covered, // if trip_type == "one-way" || "return", then this field should fill
      "hr_covered":numHours, // if trip_type == "hour", then this field should fill
      "trip_type":tripType,
      "predefined_route":route,
    }).then(res => {
      console.log(res,"res");
      setVehicleTypes(res?.data?.data)
    }).catch(err => console.log(err,"quote err") ).finally(() => setLoading(false))
  }, [])

  // const handlePrev = (type, setIndex, length) => {
  //   setIndex(prevIndex => (prevIndex === 0 ? length - 1 : prevIndex - 1));
  // };

  // const handleNext = (type, setIndex, length) => {
  //   setIndex(prevIndex => (prevIndex === length - 1 ? 0 : prevIndex + 1));
  // };


  return (
    <section className='single-transport-section'>
      <div className='bcg'></div>
      <div className='single-transport-container'>
        <Navbar />
       {!predefined && <Book trip_type={tripType} r_date={returnDate} d_date={departureDate} num_hrs={numHours} num_people={numPeople} from_place={from} to_place={to} />}


        <div className='transfer-vehicles'>
          {/* <div className='top'>
            <h4>My Transfer Vehicle</h4>
            <p>Step 1 of 3</p>
          </div> */}
          {/* <div className='vehicle-content'>
            <img
              src='https://connectotransfers.com/static/modular/img/vehicles/eu/fv2/minibus.jpg'
              alt=''
            />
            <div className='info'>
              <h5>The Economy Vehicle</h5>
              <p>
                4.9 🌟🌟🌟🌟🌟 <span>1.7k reviews</span>
              </p>
              <p className='learn'>Learn More</p>
              <ul>
                <li>
                  <FaUser /> Place for 7 people
                </li>
                <li>
                  <FaSuitcase /> Fits 8 medium suitcase
                </li>
              </ul>
            </div>
            <div className='info'>
              <ul>
                <li>
                  <FaSuitcase /> Fits 8 medium suitcase
                </li>
                <li>
                  <FaSuitcase /> Fits 8 medium suitcase
                </li>
                <li>
                  <FaSuitcase /> Fits 8 medium suitcase
                </li>
              </ul>
              <p className='small'>39 vehicles booked in the last 24 hours!</p>
              <button className='btn date-btn'>
                Choose date <FaChevronRight />
              </button>
            </div>
          </div> */}


          <h2>Our vehicle ranges</h2>
          {loading && <div className="text-center" >
            <Spinner animation="border" variant="primary" />
            <h4 className="mt-1" >Loading...</h4>
          </div>}
          <div className='other-vehicles'>
          {!loading && vehicleTypes?.filter(k =>  k.maxPassengers >= numPeople  )?.map((v,i) => {
            return <VehicleCard
            key={i}
                    detail={v}
                    imgs={v.imgs}
                    rate={v.rate}
                    model={v.models[0]}
                    models={v.models}
                    vehicle_type={v?.vehicle_type}
                    maxPassengers={v.maxPassengers}
                    quote={v?.quoteAmount}
                    maxBaggage={v.maxBaggage}
               
                  />
          })}
          

            

            {/* <div className="luxe-vehicles">
              <h2>Luxury Vehicles</h2>
                <div className='vehicle-cards'>
                  <button className="sliders-btn sliders-btn-left" onClick={() => handlePrev('luxe', setLuxeIndex, vehicleTypes.luxe.models.length)}><FaChevronLeft /></button>
                  <VehicleCard
                    img={vehicleTypes.luxe.imgs[luxeIndex]}
                    rate={vehicleTypes.luxe.rate}
                    model={vehicleTypes.luxe.models[luxeIndex]}
                    maxPassengers={vehicleTypes.luxe.maxPassengers}
                    maxBaggage={vehicleTypes.luxe.maxBaggage}
                    onClick={() => GetQuote("luxe")}

                  />
                  <button className="sliders-btn sliders-btn-right" onClick={() => handleNext('luxe', setLuxeIndex, vehicleTypes.luxe.models.length)}><FaChevronRight /></button>
                </div>
            </div>

            <div className="executive-vehicles">
              <h2>Executive Vehicles</h2>
                <div className='vehicle-cards'>
                  <button className="sliders-btn sliders-btn-left" onClick={() => handlePrev('executive', setExecutiveIndex, vehicleTypes.executive.models.length)}><FaChevronLeft /></button>
                  <VehicleCard
                  img={vehicleTypes.executive.imgs[executiveIndex]}
                    rate={vehicleTypes.executive.rate}
                    model={vehicleTypes.executive.models[executiveIndex]}
                    maxPassengers={vehicleTypes.executive.maxPassengers}
                    maxBaggage={vehicleTypes.executive.maxBaggage}
                    onClick={() => GetQuote("executive")}

                  />
                  <button className="sliders-btn sliders-btn-right" onClick={() => handleNext('executive', setExecutiveIndex, vehicleTypes.executive.models.length)}><FaChevronRight /></button>
                </div>
            </div>
            
            <div className="electric-vehicles">
              <h2>Electric Vehicles</h2>
                <div className='vehicle-cards'>
                  <button className="sliders-btn sliders-btn-left" onClick={() => handlePrev('electric', setElectricIndex, vehicleTypes.electric.models.length)}><FaChevronLeft /></button>
                  <VehicleCard
                  img={vehicleTypes.electric.imgs[electricIndex]}
                    rate={vehicleTypes.electric.rate}
                    model={vehicleTypes.electric.models[electricIndex]}
                    maxPassengers={vehicleTypes.electric.maxPassengers}
                    maxBaggage={vehicleTypes.electric.maxBaggage}
                    onClick={() => GetQuote("electric")}

                  />
                  <button className="sliders-btn sliders-btn-right" onClick={() => handleNext('electric', setElectricIndex, vehicleTypes.electric.models.length)}><FaChevronRight /></button>
                </div>
            </div>
            
            <div className="7-seater-vehicles">
              <h2>7-Seater Vehicles</h2>
              <div className='vehicle-cards'>
                <button className="sliders-btn sliders-btn-left" onClick={() => handlePrev('7-seater', setSeaterIndex, vehicleTypes['7-seater'].models.length)}><FaChevronLeft /></button>
                <VehicleCard
                img={vehicleTypes['7-seater'].imgs[seaterIndex]}
                  rate={vehicleTypes['7-seater'].rate}
                  model={vehicleTypes['7-seater'].models[seaterIndex]}
                  maxPassengers={vehicleTypes['7-seater'].maxPassengers}
                  maxBaggage={vehicleTypes['7-seater'].maxBaggage}
                  onClick={() => GetQuote("7-seater")}

                />
                <button className="sliders-btn sliders-btn-right" onClick={() => handleNext('7-seater', setSeaterIndex, vehicleTypes['7-seater'].models.length)}><FaChevronRight /></button>
              </div>
            </div> */}
            
            
            {/* <div className='vehicle-content'>
              <img
                src='https://connectotransfers.com/static/modular/img/vehicles/eu/fv2/minibus.jpg'
                alt=''
              />
              <div className='info'>
                <h5>The Economy Vehicle</h5>
                <p>
                  4.9 🌟🌟🌟🌟🌟 <span>1.7k reviews</span>
                </p>
                <p className='learn'>Learn More</p>
                <ul>
                  <li>
                    <FaSuitcase /> Fits 8 medium suitcase
                  </li>
                  <li>
                    <FaSuitcase /> Fits 8 medium suitcase
                  </li>
                </ul>
              </div>
              <div className='info'>
                <small>One way price:</small>
                <h4>Choose date</h4>
                <button className='btn date-btn'>
                  Choose this vehicle <FaChevronRight />
                </button>
              </div>
            </div>
            <div className='vehicle-content'>
              <img
                src='https://connectotransfers.com/static/modular/img/vehicles/eu/fv2/minibus.jpg'
                alt=''
              />
              <div className='info'>
                <h5>The Economy Vehicle</h5>
                <p>
                  4.9 🌟🌟🌟🌟🌟 <span>1.7k reviews</span>
                </p>
                <p className='learn'>Learn More</p>
                <ul>
                  <li>
                    <FaSuitcase /> Fits 8 medium suitcase
                  </li>
                  <li>
                    <FaSuitcase /> Fits 8 medium suitcase
                  </li>
                </ul>
              </div>
              <div className='info'>
                <small>One way price:</small>
                <h4>Choose date</h4>
                <button className='btn date-btn'>
                  Choose this vehicle <FaChevronRight />
                </button>
              </div>
            </div>
            <div className='vehicle-content'>
              <img
                src='https://connectotransfers.com/static/modular/img/vehicles/eu/fv2/minibus.jpg'
                alt=''
              />
              <div className='info'>
                <h5>The Economy Vehicle</h5>
                <p>
                  4.9 🌟🌟🌟🌟🌟 <span>1.7k reviews</span>
                </p>
                <p className='learn'>Learn More</p>
                <ul>
                  <li>
                    <FaSuitcase /> Fits 8 medium suitcase
                  </li>
                  <li>
                    <FaSuitcase /> Fits 8 medium suitcase
                  </li>
                </ul>
              </div>
              <div className='info'>
                <small>One way price:</small>
                <h4>Choose date</h4>
                <button className='btn date-btn'>
                  Choose this vehicle <FaChevronRight />
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
}

export default SingleTransport;
