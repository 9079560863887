import styled from "styled-components";

export const FooterWrapper = styled.div`
  background-color: black;
  font-family: "Open Sans", sans-serif;
  padding: 50px 0;

  .footer-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 2rem;

    .flex-div {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 1rem;

      @media (max-width: 992px) {
        flex-direction: column;
        gap: 2rem;
      }
    }
  }
`;

export const LeftDiv = styled.div`
  flex: 1;

  img {
    width: 100px;
  }

  p {
    color: white;
    margin-top: 1rem;
    font-weight: 500;
  }
`;

export const SecondDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  flex: 1;

  @media (max-width: 992px) {
    align-items: flex-start;
    gap: 8px;
  }
`;

export const UseFulLinksDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  flex: 1;

  @media (max-width: 992px) {
    padding-left: 0rem;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const ContactDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 16px;
  flex: 1;

  @media (max-width: 992px) {
    align-items: flex-start;
    gap: 8px;
  }
`;

export const LinksHeader = styled.h4`
  color: white;
  font-weight: 600;
  font-size: 20px;
  width: 180px;

  @media (max-width: 992px) {
    width: fit-content;
  }
`;

export const FooterLinks = styled.p`
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  width: 180px;

  @media (max-width: 992px) {
    width: fit-content;
  }

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

export const BottomFooterDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 2rem;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .left-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    gap: 2rem;

    @media (max-width: 992px) {
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
    }

    .copy-rights-text {
      color: white;
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }
  }

  .right-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
`;

export const FooterBottomLinks = styled(FooterLinks)`
  width: fit-content;
`;
