import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function PaymentResult({result}) {

    const navigate = useNavigate()

let bookingDetails = JSON.parse(localStorage.getItem("booking-details"))

console.log(bookingDetails,'bookingDetails');

    useEffect(() => {
      axios.post("https://glittery-cajeta-6bbaa7.netlify.app/.netlify/functions/api/bookingConfirm",{...bookingDetails,type:result}).then(res => {
        console.log(res,"res");
      
      }).catch(err => console.log(err,"quote err") )
    }, [])

    useEffect(() => {
        setTimeout(() => {
     navigate("/")
        }, 5000);
    }, [])
    

  return (

    <div style={{textAlign:"center"}} >
    <h2> {result == "success" ? "Payment Successfull" : "Payment Fail" }</h2>
    <p>Redirecting to homepage </p> 
    </div>
  )
}

export default PaymentResult